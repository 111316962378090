"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setAppHeartBeat = exports.useAppHeartBeat = exports.setAuthentication = exports.useAuthentication = exports.initApp = void 0;

var _CarrierActions = require("../../carrier/reducer/CarrierActions");

var _CarrierService = require("../../carrier/reducer/CarrierService");

var _ProductService = require("../../product/reducer/ProductService");

var _RoleService = require("../../user-roles/reducer/RoleService");

var _AgentService = require("../../agents/reducer/AgentService");

var _PolicyService = require("../../policies/reducer/PolicyService");

var _UtilService = require("../../utils/service/UtilService");

var _hooksForRedux = require("../../provider/hooks-for-redux");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var initApp = function initApp() {
  console.log('Init App Called'); //TODO: Remove fetchAllCarriers() because fetchCarrierMaster should be used

  (0, _CarrierService.fetchAllCarriers)().then(function (response) {
    if (response.ok === true) {
      (0, _CarrierActions.setCarrier)(response.data);
    } else if (response.ok === false) {
      console.error(response);
    }
  })["catch"](function (err) {
    console.error(err);
  });
  (0, _ProductService.fetchAllProductGroupInit)();
  (0, _RoleService.fetchAllRoles)(); // TODO: should be called only if user is client admin

  (0, _AgentService.fetchAllUsersForDropdown)(); //TODO: This is temporary for Agents to select various users

  (0, _ProductService.fetchProductTypeMaster)();
  (0, _ProductService.fetchSkills)();
  (0, _PolicyService.fetchPolicyTypeMaster)();
  (0, _UtilService.fetchSourceMaster)();
  (0, _UtilService.fetchAssignedGroupMaster)();
  (0, _UtilService.fetchDispositionMaster)();
  (0, _CarrierService.fetchCarrierMaster)(); //fetchCampaignsMaster called within fetchCarrierMaster because carrier is dependency

  (0, _UtilService.fetchAgentsMaster)();
};

exports.initApp = initApp;

var _useRedux = (0, _hooksForRedux.useRedux)('login', {
  authenticationLoading: false,
  error: false,
  errorMsg: '',
  data: {}
}, {
  setAuthentication: function setAuthentication(oldState, newState) {
    return _objectSpread(_objectSpread({}, oldState), newState);
  }
}),
    _useRedux2 = _slicedToArray(_useRedux, 2),
    useAuthentication = _useRedux2[0],
    setAuthentication = _useRedux2[1].setAuthentication;

exports.setAuthentication = setAuthentication;
exports.useAuthentication = useAuthentication;

var _useRedux3 = (0, _hooksForRedux.useRedux)('heartBeat', {
  loading: false,
  error: false,
  errorMsg: '',
  data: []
}, {
  setAppHeartBeat: function setAppHeartBeat(oldState, newState) {
    return _objectSpread(_objectSpread({}, oldState), newState);
  }
}),
    _useRedux4 = _slicedToArray(_useRedux3, 2),
    useAppHeartBeat = _useRedux4[0],
    setAppHeartBeat = _useRedux4[1].setAppHeartBeat;

exports.setAppHeartBeat = setAppHeartBeat;
exports.useAppHeartBeat = useAppHeartBeat;