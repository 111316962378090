"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ColumnDefs = void 0;

var _datagrid = require("../../../../datagrid");

var ColumnDefs = [{
  field: 'accountId',
  headerName: 'AccountId'
}, {
  field: 'accountName',
  headerName: 'Account Name'
}, {
  field: 'add1',
  headerName: 'Address 1'
}, {
  field: 'add2',
  headerName: 'Address 2'
}, {
  field: 'city',
  headerName: 'City'
}, {
  field: 'state',
  headerName: 'State'
}, {
  field: 'zip',
  headerName: 'Zip'
}, {
  field: 'county',
  headerName: 'County'
}, {
  field: 'primaryPhone',
  headerName: 'Primary Phone'
}];
exports.ColumnDefs = ColumnDefs;