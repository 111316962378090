"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.US_STATES = void 0;
var US_STATES = [{
  id: 'AL',
  label: 'Alabama'
}, {
  id: 'AK',
  label: 'Alaska'
}, {
  id: 'AS',
  label: 'American Samoa'
}, {
  id: 'AZ',
  label: 'Arizona'
}, {
  id: 'AR',
  label: 'Arkansas'
}, {
  id: 'CA',
  label: 'California'
}, {
  id: 'CO',
  label: 'Colorado'
}, {
  id: 'CT',
  label: 'Connecticut'
}, {
  id: 'DC',
  label: 'District of Columbia'
}, {
  id: 'DE',
  label: 'Delaware'
}, {
  id: 'FM',
  label: 'Federated States of Micronesia'
}, {
  id: 'FL',
  label: 'Florida'
}, {
  id: 'GA',
  label: 'Georgia'
}, {
  id: 'GU',
  label: 'Guam'
}, {
  id: 'HI',
  label: 'Hawaii'
}, {
  id: 'ID',
  label: 'Idaho'
}, {
  id: 'IL',
  label: 'Illinois'
}, {
  id: 'IN',
  label: 'Indiana'
}, {
  id: 'IA',
  label: 'Iowa'
}, {
  id: 'KS',
  label: 'Kansas'
}, {
  id: 'KY',
  label: 'Kentucky'
}, {
  id: 'LA',
  label: 'Louisiana'
}, {
  id: 'ME',
  label: 'Maine'
}, {
  id: 'MD',
  label: 'Maryland'
}, {
  id: 'MA',
  label: 'Massachusetts'
}, {
  id: 'MH',
  label: 'Marshall Islands'
}, {
  id: 'MI',
  label: 'Michigan'
}, {
  id: 'MN',
  label: 'Minnesota'
}, {
  id: 'MS',
  label: 'Mississippi'
}, {
  id: 'MO',
  label: 'Missouri'
}, {
  id: 'MT',
  label: 'Montana'
}, {
  id: 'NE',
  label: 'Nebraska'
}, {
  id: 'NV',
  label: 'Nevada'
}, {
  id: 'NH',
  label: 'New Hampshire'
}, {
  id: 'NJ',
  label: 'New Jersey'
}, {
  id: 'NM',
  label: 'New Mexico'
}, {
  id: 'NY',
  label: 'New York'
}, {
  id: 'NC',
  label: 'North Carolina'
}, {
  id: 'ND',
  label: 'North Dakota'
}, {
  id: 'MP',
  label: 'Northern Mariana Islands'
}, {
  id: 'OH',
  label: 'Ohio'
}, {
  id: 'OK',
  label: 'Oklahoma'
}, {
  id: 'OR',
  label: 'Oregon'
}, {
  id: 'PW',
  label: 'Palau'
}, {
  id: 'PA',
  label: 'Pennsylvania'
}, {
  id: 'PR',
  label: 'Puerto Rico'
}, {
  id: 'RI',
  label: 'Rhode Island'
}, {
  id: 'SC',
  label: 'South Carolina'
}, {
  id: 'SD',
  label: 'South Dakota'
}, {
  id: 'TN',
  label: 'Tennessee'
}, {
  id: 'TX',
  label: 'Texas'
}, {
  id: 'UT',
  label: 'Utah'
}, {
  id: 'VT',
  label: 'Vermont'
}, {
  id: 'VA',
  label: 'Virginia'
}, {
  id: 'VI',
  label: 'Virgin Islands'
}, {
  id: 'WA',
  label: 'Washington'
}, {
  id: 'WV',
  label: 'West Virginia'
}, {
  id: 'WI',
  label: 'Wisconsin'
}, {
  id: 'WY',
  label: 'Wyoming'
}];
exports.US_STATES = US_STATES;