"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.triggerStageLeadMock = exports.uploadLeadsFileMock = void 0;

var uploadLeadsFileMock = function uploadLeadsFileMock(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      resolve({
        ok: true,
        data: [],
        message: 'success'
      });
    }, 500);
  });
};

exports.uploadLeadsFileMock = uploadLeadsFileMock;

var triggerStageLeadMock = function triggerStageLeadMock(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      resolve({
        ok: true,
        data: [],
        message: 'success'
      });
    }, 500);
  });
};

exports.triggerStageLeadMock = triggerStageLeadMock;