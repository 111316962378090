"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUserData = void 0;

var _ = require("../../");

var _axios = _interopRequireDefault(require("axios"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var getUserData = function getUserData(email) {
  return (0, _axios["default"])({
    method: 'get',
    url: "".concat(_.serviceContext, "/userView/email/").concat(email),
    data: {}
  }).then(function (_ref) {
    var data = _ref.data;
    // return JSON.parse(data);
    return data;
  });
};

exports.getUserData = getUserData;