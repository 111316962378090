"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchSkills = exports.fetchProductTypeMaster = exports.createOrUpdateProduct = exports.fetchProductGroupsByProductType = exports.fetchProductsByProductGroup = exports.fetchAllProductGroupInit = exports.fetchAllProductGroup = exports.createOrUpdateProductGroup = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _2 = require("../../");

var _lodash = _interopRequireDefault(require("lodash"));

var _ProductActions = require("./ProductActions");

var productServiceMock = _interopRequireWildcard(require("./ProductMock"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var createOrUpdateProductGroup = function createOrUpdateProductGroup(params) {
  if (_2.isMock) {
    return productServiceMock.createOrUpdateProductGroup(params);
  }

  return (0, _axios["default"])({
    method: 'post',
    url: "".concat(_2.serviceContext, "/productGroup"),
    data: params
  }).then(function (_ref) {
    var data = _ref.data;
    // return JSON.parse(data);
    return data;
  });
};

exports.createOrUpdateProductGroup = createOrUpdateProductGroup;

var fetchAllProductGroup = function fetchAllProductGroup() {
  if (_2.isMock) {
    return productServiceMock.fetchAllProductGroup();
  }

  return (0, _axios["default"])({
    method: 'get',
    url: "".concat(_2.serviceContext, "/productGroup"),
    data: {}
  }).then(function (_ref2) {
    var data = _ref2.data;
    // return JSON.parse(data);
    return data;
  });
};

exports.fetchAllProductGroup = fetchAllProductGroup;

var fetchAllProductGroupInit = function fetchAllProductGroupInit() {
  if (_2.isMock) {
    return productServiceMock.fetchAllProductGroupInit();
  }

  return (0, _axios["default"])({
    method: 'get',
    url: "".concat(_2.serviceContext, "/productGroup"),
    data: {}
  }).then(function (_ref3) {
    var data = _ref3.data;
    // return JSON.parse(data);
    return data;
  }).then(function (response) {
    if (response.ok === true) {
      (0, _ProductActions.setProductGroup)(response.data);
    } else if (response.ok === false) {
      (0, _ProductActions.setProductGroupError)();
    }
  })["catch"](function (err) {
    console.error(err);
    (0, _ProductActions.setProductGroupError)();
  });
};

exports.fetchAllProductGroupInit = fetchAllProductGroupInit;

var fetchProductsByProductGroup = function fetchProductsByProductGroup(productGroup) {
  if (_2.isMock) {
    return productServiceMock.fetchProductsByProductGroup(productGroup);
  }

  return (0, _axios["default"])({
    method: 'get',
    url: "".concat(_2.serviceContext, "/product/productGroup/").concat(productGroup.productGroupId, "/carrier/").concat(productGroup.carrierId),
    data: {}
  }).then(function (_ref4) {
    var data = _ref4.data;
    // return JSON.parse(data);
    return data;
  });
};

exports.fetchProductsByProductGroup = fetchProductsByProductGroup;

var fetchProductGroupsByProductType = function fetchProductGroupsByProductType() {
  var productType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

  if (_2.isMock) {
    return productServiceMock.fetchProductGroupsByProductType(productType);
  }

  return (0, _axios["default"])({
    method: 'get',
    url: "".concat(_2.serviceContext, "/productGroup/productType/").concat(productType),
    data: {}
  }).then(function (_ref5) {
    var data = _ref5.data;
    // return JSON.parse(data);
    return data;
  });
};

exports.fetchProductGroupsByProductType = fetchProductGroupsByProductType;

var createOrUpdateProduct = function createOrUpdateProduct(params) {
  if (_2.isMock) {
    return productServiceMock.createOrUpdateProduct(params);
  }

  return (0, _axios["default"])({
    method: 'post',
    url: "".concat(_2.serviceContext, "/product"),
    data: params
  }).then(function (_ref6) {
    var data = _ref6.data;
    // return JSON.parse(data);
    return data;
  });
};

exports.createOrUpdateProduct = createOrUpdateProduct;

var fetchProductTypeMaster = function fetchProductTypeMaster() {
  if (_2.isMock) {
    return productServiceMock.fetchProductTypeMaster();
  }

  return (0, _axios["default"])({
    method: 'get',
    url: "".concat(_2.serviceContext, "/productType"),
    data: {}
  }).then(function (_ref7) {
    var data = _ref7.data;
    // return JSON.parse(data);
    return data;
  }).then(function (response) {
    if (response.ok === true) {
      var responseData = response.data ? response.data : [];

      var sortedData = _lodash["default"].sortBy(responseData, ['productTypeDesc']);

      (0, _ProductActions.setProductTypeMaster)({
        productTypeLoading: false,
        error: false,
        errorMsg: '',
        data: sortedData
      });
    } else if (response.ok === false) {
      (0, _ProductActions.setProductTypeMaster)({
        productTypeLoading: false,
        error: true,
        errorMsg: 'Product Type Service Failed',
        data: []
      });
    }
  })["catch"](function (err) {
    console.error(err);
    (0, _ProductActions.setProductTypeMaster)({
      productTypeLoading: false,
      error: true,
      errorMsg: 'Product Type Service Failed',
      data: []
    });
  });
};

exports.fetchProductTypeMaster = fetchProductTypeMaster;

var fetchSkills = function fetchSkills() {
  return (0, _axios["default"])({
    method: 'get',
    url: "".concat(_2.serviceContext, "/lookup/type/Skills"),
    data: {}
  }).then(function (_ref8) {
    var data = _ref8.data;
    // return JSON.parse(data);
    return data;
  }).then(function (response) {
    if (response.ok === true) {
      var responseData = response.data ? response.data : [];

      var sortedData = _lodash["default"].sortBy(responseData, ['name']);

      (0, _ProductActions.setSkills)({
        skillsLoading: false,
        error: false,
        errorMsg: '',
        data: sortedData
      });
    } else if (response.ok === false) {
      (0, _ProductActions.setSkills)({
        skillsLoading: false,
        error: true,
        errorMsg: 'Skills Service Failed',
        data: []
      });
    }
  })["catch"](function (err) {
    console.error(err);
    (0, _ProductActions.setSkills)({
      skillsLoading: false,
      error: true,
      errorMsg: 'Skills Service Failed',
      data: []
    });
  });
};

exports.fetchSkills = fetchSkills;