"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setSkills = exports.useSkills = exports.setProductTypeMaster = exports.useProductTypeMaster = exports.setProductGroupError = exports.setProductGroup = exports.useProductGroup = void 0;

var _hooksForRedux = require("../../provider/hooks-for-redux");

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var _useRedux = (0, _hooksForRedux.useRedux)('productGroup', {
  loading: false,
  error: false,
  errorMsg: '',
  data: [//   {
    //     productGroupId: 5,
    //     carrierId: 1,
    //     productGroupName: 'Test',
    //     productGroupDesc: 'Test',
    //     createdBy: 'yogi',
    //     createdDateTime: '2020-06-03T18:38:18.690+00:00',
    //     modifiedBy: 'yogi',
    //     modifiedDateTime: '2020-06-03T18:38:18.690+00:00'
    //   },
    //   {
    //     productGroupId: 6,
    //     carrierId: 1,
    //     productGroupName: 'Test 2',
    //     productGroupDesc: 'Test 2',
    //     createdBy: 'yogi',
    //     createdDateTime: '2020-06-03T18:38:18.690+00:00',
    //     modifiedBy: 'yogi',
    //     modifiedDateTime: '2020-06-03T18:38:18.690+00:00'
    //   },
    //   {
    //     productGroupId: 6,
    //     carrierId: 1,
    //     productGroupName: 'Test 2',
    //     productGroupDesc: 'Test 2',
    //     createdBy: 'yogi',
    //     createdDateTime: '2020-06-03T18:38:18.690+00:00',
    //     modifiedBy: 'yogi',
    //     modifiedDateTime: '2020-06-03T18:38:18.690+00:00'
    //   }
  ]
}, {
  setProductGroup: function setProductGroup(productGroup, productGroupArr) {
    return _objectSpread(_objectSpread({}, productGroup), {}, {
      loading: false,
      errorMsg: '',
      error: false,
      data: _toConsumableArray(productGroupArr)
    });
  },
  setProductGroupError: function setProductGroupError(productGroup) {
    var errorMsg = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'Failed to load Product Group';
    return _objectSpread(_objectSpread({}, productGroup), {}, {
      loading: false,
      error: true,
      errorMsg: errorMsg,
      data: []
    });
  }
}),
    _useRedux2 = _slicedToArray(_useRedux, 2),
    useProductGroup = _useRedux2[0],
    _useRedux2$ = _useRedux2[1],
    setProductGroup = _useRedux2$.setProductGroup,
    setProductGroupError = _useRedux2$.setProductGroupError;

exports.setProductGroupError = setProductGroupError;
exports.setProductGroup = setProductGroup;
exports.useProductGroup = useProductGroup;

var _useRedux3 = (0, _hooksForRedux.useRedux)('product-type-master', {
  productTypeLoading: false,
  error: false,
  errorMsg: '',
  data: []
}, {
  setProductTypeMaster: function setProductTypeMaster(oldState, newState) {
    return _objectSpread(_objectSpread({}, oldState), newState);
  }
}),
    _useRedux4 = _slicedToArray(_useRedux3, 2),
    useProductTypeMaster = _useRedux4[0],
    setProductTypeMaster = _useRedux4[1].setProductTypeMaster;

exports.setProductTypeMaster = setProductTypeMaster;
exports.useProductTypeMaster = useProductTypeMaster;

var _useRedux5 = (0, _hooksForRedux.useRedux)('skills', {
  skillsLoading: false,
  error: false,
  errorMsg: '',
  data: []
}, {
  setSkills: function setSkills(oldState, newState) {
    return _objectSpread(_objectSpread({}, oldState), newState);
  }
}),
    _useRedux6 = _slicedToArray(_useRedux5, 2),
    useSkills = _useRedux6[0],
    setSkills = _useRedux6[1].setSkills;

exports.setSkills = setSkills;
exports.useSkills = useSkills;