"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PValue = exports.PLabel = exports.PTitle = exports.PHeader = exports.PRow = exports.PHeaderRow = exports.PBox = void 0;

var _react = _interopRequireDefault(require("react"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  //   border: 1px solid #4378ae;\n  text-align: right;\n  padding: 0px 5px;\n  // text-overflow: ellipsis;\n  // overflow: hidden;\n  flex-basis: 50%;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  font-weight: bold;\n  //   border: 1px solid #4378ae;\n  padding: 0px 5px;\n  text-overflow: ellipsis;\n  overflow: hidden;\n\n  line-height: 33px;\n  flex-basis: 50%;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  color: white;\n  background: black;\n  border: 1px solid #4378ae;\n  padding: 0px 5px;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  //   flex-direction: row;\n  //   margin: 10px 0px;\n  margin-bottom: 10px;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var PBox = _styledComponents["default"].div(_templateObject());

exports.PBox = PBox;

var PHeaderRow = _styledComponents["default"].div(_templateObject2());

exports.PHeaderRow = PHeaderRow;

var PRow = _styledComponents["default"].div(_templateObject3());

exports.PRow = PRow;

var PHeader = _styledComponents["default"].div(_templateObject4());

exports.PHeader = PHeader;

var PTitle = _styledComponents["default"].span(_templateObject5());

exports.PTitle = PTitle;

var PLabel = _styledComponents["default"].span(_templateObject6());

exports.PLabel = PLabel;

var PValue = _styledComponents["default"].span(_templateObject7());

exports.PValue = PValue;