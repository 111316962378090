"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GENDER_LIST = void 0;
var GENDER_LIST = [{
  id: 1,
  label: 'Male'
}, {
  id: 2,
  label: 'Female'
}, {
  id: 3,
  label: 'Unknown'
}];
exports.GENDER_LIST = GENDER_LIST;