"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mockResponse = void 0;

var mockResponse = function mockResponse(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [];
      resolve({
        ok: true,
        data: data
      });
    }, 500);
  });
};

exports.mockResponse = mockResponse;