"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "DependentsSection", {
  enumerable: true,
  get: function get() {
    return _DependentsSection.DependentsSection;
  }
});
Object.defineProperty(exports, "DocumentSection", {
  enumerable: true,
  get: function get() {
    return _DocumentSection.DocumentSection;
  }
});
Object.defineProperty(exports, "ActivityLogSection", {
  enumerable: true,
  get: function get() {
    return _ActivityLogSection.ActivityLogSection;
  }
});
Object.defineProperty(exports, "HistorySection", {
  enumerable: true,
  get: function get() {
    return _HistorySection.HistorySection;
  }
});
Object.defineProperty(exports, "DocumentSectionWrapper", {
  enumerable: true,
  get: function get() {
    return _DocumentSectionWrapper.DocumentSectionWrapper;
  }
});

var _DependentsSection = require("./dependents/DependentsSection");

var _DocumentSection = require("./documents/DocumentSection");

var _ActivityLogSection = require("./activity/ActivityLogSection");

var _HistorySection = require("./history/HistorySection");

var _DocumentSectionWrapper = require("./documents/DocumentSectionWrapper");