"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AC_GRID_DEFAULT_ROW_ClASS_RULES = exports.AC_GRID_DEFAULT_OPTIONS = void 0;

var _ACGridLoading = require("./ACGridLoading");

var _ACGridNoRecords = require("./ACGridNoRecords");

var _ACStatusRenderer = require("./ACStatusRenderer");

var AC_GRID_DEFAULT_OPTIONS = {
  frameworkComponents: {
    statusRenderer: _ACStatusRenderer.ACStatusRenderer,
    customLoadingOverlay: _ACGridLoading.ACGridLoading,
    customNoRowsOverlay: _ACGridNoRecords.ACGridNoRecords
  },
  loadingOverlayComponent: 'customLoadingOverlay',
  loadingOverlayComponentParams: {
    loadingMessage: 'Please wait while your activities are loading...'
  },
  noRowsOverlayComponent: 'customNoRowsOverlay',
  noRowsOverlayComponentParams: {
    noRowsMessageFunc: function noRowsMessageFunc() {
      return 'Sorry - no rows! at: ' + new Date();
    }
  },
  defaultColDef: {
    flex: 1,
    minWidth: 150,
    filter: true,
    sortable: true,
    resizable: true,
    filterParams: {
      buttons: ['apply', 'reset'],
      closeOnApply: true,
      suppressAndOrCondition: true
    }
  }
};
exports.AC_GRID_DEFAULT_OPTIONS = AC_GRID_DEFAULT_OPTIONS;
var AC_GRID_DEFAULT_ROW_ClASS_RULES = {
  'opportunity-completed': function opportunityCompleted(params) {
    return params.data.leadStatus === 'Win';
  },
  'ac-grid-row-loading': function acGridRowLoading(params) {
    return params.data.loading;
  },
  'sick-days-breach': 'data.sickDays >= 8'
};
exports.AC_GRID_DEFAULT_ROW_ClASS_RULES = AC_GRID_DEFAULT_ROW_ClASS_RULES;