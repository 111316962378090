"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ColumnDefs = void 0;

var _datagrid = require("../../../../datagrid");

var ColumnDefs = [{
  headerName: 'Type',
  colId: 'Name' // valueGetter: function (params) {
  //   return params.data.customerFname + ' ' + params.data.customerLname;
  // }

}, {
  // field: 'phone1',
  colId: 'phone1',
  headerName: 'Subtype'
}, {
  // field: 'leadStatus',
  colId: 'leadStatus',
  headerName: 'Status',
  cellRenderer: 'statusRenderer'
}, {
  // field: 'leadStatus',
  colId: 'leadStatus',
  headerName: 'Sub-Status',
  cellRenderer: 'statusRenderer'
}, {
  // field: 'dobGrid',
  colId: 'dobGrid',
  headerName: 'Status Changed Date',
  comparator: _datagrid.ACDateComparatorSorting,
  filter: 'agDateColumnFilter' // filterParams: {
  //   buttons: ['apply', 'reset'],
  //   closeOnApply: true,
  //   suppressAndOrCondition: true,
  //   comparator: ACDateComparatorFiltering,
  //   browserDatePicker: true
  // }

}, {
  headerName: 'Account',
  colId: 'Name'
}, {
  headerName: 'Contact',
  colId: 'Name'
}, {
  headerName: 'Priority',
  colId: 'Name'
}, {
  // field: 'dobGrid',
  colId: 'dobGrid',
  headerName: 'Due Date',
  comparator: _datagrid.ACDateComparatorSorting,
  filter: 'agDateColumnFilter',
  filterParams: {
    buttons: ['apply', 'reset'],
    closeOnApply: true,
    suppressAndOrCondition: true,
    comparator: _datagrid.ACDateComparatorFiltering,
    browserDatePicker: true
  }
}, {
  headerName: 'Assigned To',
  colId: 'Name'
}, {
  // field: 'dobGrid',
  colId: 'dobGrid',
  headerName: 'Assigned To Date',
  comparator: _datagrid.ACDateComparatorSorting,
  filter: 'agDateColumnFilter'
}, {
  headerName: 'Assigned By',
  colId: 'Name'
}, {
  // field: 'dobGrid',
  colId: 'dobGrid',
  headerName: 'Assigned By Date',
  comparator: _datagrid.ACDateComparatorSorting,
  filter: 'agDateColumnFilter'
}];
exports.ColumnDefs = ColumnDefs;