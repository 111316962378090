"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.olympicWinners = void 0;
var olympicWinners = [{
  athlete: 'Michael Phelps',
  age: 23,
  country: 'United States',
  year: 2008,
  date: '24/08/2008',
  sport: 'Swimming',
  gold: 8,
  silver: 0,
  bronze: 0,
  total: 8
}, {
  athlete: 'Michael Phelps',
  age: 19,
  country: 'United States',
  year: 2004,
  date: '29/08/2004',
  sport: 'Swimming',
  gold: 6,
  silver: 0,
  bronze: 2,
  total: 8
}];
exports.olympicWinners = olympicWinners;