"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createOrUpdateNotes = exports.fetchNotes = exports.fetchMyUse = exports.createOrUpdateMyTasks = exports.fetchMyTasks = exports.fetchCont = exports.fetchAccount = exports.createOrUpdateMyActivities = exports.transformUsersResponse = exports.fetchMyActivities = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _lodash = _interopRequireDefault(require("lodash"));

var _moment = _interopRequireDefault(require("moment"));

var _uuid = require("uuid");

var _2 = require("../../");

var myactivitiesMock = _interopRequireWildcard(require("./MyActivitiesMock"));

var _utils = require("../../utils");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var fetchMyActivities = function fetchMyActivities() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    activityId: 1
  };

  if (_2.isMock) {
    return myactivitiesMock.fetchMyActivities(params);
  }

  return (0, _axios["default"])({
    method: 'get',
    url: "".concat(_2.serviceContext, "/activity/assignedTo/").concat(params.userId),
    data: params,
    transformResponse: [function (data) {
      return data;
    }]
  }).then(function (_ref) {
    var data = _ref.data;
    return JSON.parse(data); // return data;
  }).then(function (response) {
    if (response.ok === true) {
      var data = transformUsersResponse(response.data);
      response.data = data;
    }

    return response;
  });
};

exports.fetchMyActivities = fetchMyActivities;

var transformUsersResponse = function transformUsersResponse() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

  var transformedData = _lodash["default"].map(data, function (o) {
    var newObj = {
      dobGrid: o.dob ? (0, _moment["default"])(o.dob, _utils.AC_CONSTANTS.AC_DATE_FORMAT_SERVICE).format(_utils.AC_CONSTANTS.AC_DATE_FORMAT) : null
    };
    return _objectSpread(_objectSpread({}, o), newObj);
  });

  return transformedData;
};

exports.transformUsersResponse = transformUsersResponse;

var createOrUpdateMyActivities = function createOrUpdateMyActivities(params) {
  if (_2.isMock) {
    return myactivitiesMock.createOrUpdateMyActivities(params);
  }

  return (0, _axios["default"])({
    method: 'post',
    url: "".concat(_2.serviceContext, "/activity"),
    data: {
      activity: params
    }
  }).then(function (_ref2) {
    var data = _ref2.data;
    // return JSON.parse(data);
    // fetchAllUsersForDropdown(); //TODO: This is temporary for Agents to select various users
    return data;
  });
};

exports.createOrUpdateMyActivities = createOrUpdateMyActivities;

var fetchAccount = function fetchAccount(params) {
  return (0, _axios["default"])({
    method: 'get',
    url: "".concat(_2.serviceContext, "/account"),
    data: {}
  }).then(function (_ref3) {
    var data = _ref3.data;
    // return JSON.parse(data);
    return data;
  });
};

exports.fetchAccount = fetchAccount;

var fetchCont = function fetchCont(params) {
  return (0, _axios["default"])({
    method: 'get',
    url: "".concat(_2.serviceContext, "/contact"),
    data: {}
  }).then(function (_ref4) {
    var data = _ref4.data;
    // return JSON.parse(data);
    return data;
  });
};

exports.fetchCont = fetchCont;

var fetchMyTasks = function fetchMyTasks(params) {
  return (0, _axios["default"])({
    method: 'get',
    url: "".concat(_2.serviceContext, "/activityTask/activity/").concat(params.activityId),
    data: {}
  }).then(function (_ref5) {
    var data = _ref5.data;
    return data;
  });
};

exports.fetchMyTasks = fetchMyTasks;

var createOrUpdateMyTasks = function createOrUpdateMyTasks(params) {
  return (0, _axios["default"])({
    method: 'post',
    url: "".concat(_2.serviceContext, "/activityTask/").concat(params.taskId, "/action"),
    data: params
  }).then(function (_ref6) {
    var data = _ref6.data;
    // return JSON.parse(data);
    // fetchAllUsersForDropdown(); //TODO: This is temporary for Agents to select various users
    return data;
  });
};

exports.createOrUpdateMyTasks = createOrUpdateMyTasks;

var fetchMyUse = function fetchMyUse(params) {
  return (0, _axios["default"])({
    method: 'get',
    url: "".concat(_2.serviceContext, "/userView/list"),
    data: {}
  }).then(function (_ref7) {
    var data = _ref7.data;
    return data;
  });
};

exports.fetchMyUse = fetchMyUse;

var fetchNotes = function fetchNotes(params) {
  return (0, _axios["default"])({
    method: 'get',
    url: "".concat(_2.serviceContext, "/activity/").concat(params.activityId, "/audit?action=Notes"),
    data: {}
  }).then(function (_ref8) {
    var data = _ref8.data;
    return data;
  });
};

exports.fetchNotes = fetchNotes;

var createOrUpdateNotes = function createOrUpdateNotes(notes, activityId) {
  return (0, _axios["default"])({
    method: 'post',
    url: "".concat(_2.serviceContext, "/activity/").concat(activityId, "/notes"),
    data: notes,
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(function (_ref9) {
    var data = _ref9.data;
    // return JSON.parse(data);
    // fetchAllUsersForDropdown(); //TODO: This is temporary for Agents to select various users
    return data;
  });
};

exports.createOrUpdateNotes = createOrUpdateNotes;