"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createStore = void 0;

var _redux = require("redux");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

//module.exports
var createStore = function createStore() {
  var initialReducers = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    args[_key - 1] = arguments[_key];
  }

  // console.log('createStore');
  if (_typeof(initialReducers) !== 'object') {
    console.error({
      initialReducers: initialReducers,
      args: args
    });
    throw new Error('initialReducers should be an object suitable to be passed to combineReducers');
  }

  var reducers = _objectSpread(_objectSpread({}, initialReducers), {}, {
    _stub_: function _stub_(s) {
      return s || 0;
    }
  });

  var store = _redux.createStore.apply(void 0, [(0, _redux.combineReducers)(reducers)].concat(args));

  store.injectReducer = function (key, reducer) {
    if (reducers[key]) console.warn("injectReducer: replacing reducer for key '".concat(key, "'"));
    reducers[key] = reducer;
    store.replaceReducer((0, _redux.combineReducers)(reducers));
  };

  return store;
};

exports.createStore = createStore;