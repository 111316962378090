"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ItemLabel = exports.ToggleSidebar = exports.Root = void 0;

var _react = _interopRequireDefault(require("react"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  margin-left: 5px;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  z-index: 10;\n  background: orange;\n  right: -16px;\n  color: white;\n  border-radius: 50px;\n  height: 30px;\n  width: 30px;\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  top: 90px;\n  cursor: pointer;\n  // border: 1px solid #002e67;\n  // box-shadow: 0px 0px 0px 2px #002e67;\n\n  border: 3px solid #002e67;\n\n  transition: all 0.2s ease-out 0s;\n  ", "\n\n  ", ":hover & {\n    opacity: 1;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  z-index: 10;\n  display: flex;\n  flex-direction: column;\n  top: 0;\n  bottom: 0;\n  width: 200px;\n  background: black;\n  color: #e4ecfb;\n  padding-left: 0px;\n  padding-top: 60px;\n  padding-top: 70px;\n  transition: transform 0.3s ease-out 0s;\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var Root = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.showSidebar ? '' : 'transform: translateX(-200px);';
});

exports.Root = Root;

var ToggleSidebar = _styledComponents["default"].span(_templateObject2(), function (props) {
  return props.showSidebar ? 'opacity: 0;' : 'opacity: 1;';
}, Root);

exports.ToggleSidebar = ToggleSidebar;

var ItemLabel = _styledComponents["default"].div(_templateObject3());

exports.ItemLabel = ItemLabel;