"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createOrUpdateMyRequests = exports.fetchMyRequests = void 0;

var _basic = require("../../basic");

var _lodash = _interopRequireDefault(require("lodash"));

var _moment = _interopRequireDefault(require("moment"));

var _uuid = require("uuid");

var _utils = require("../../utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var fetchMyRequests = function fetchMyRequests(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [{}];
      resolve({
        ok: true,
        data: data
      });
    }, 500);
  });
};

exports.fetchMyRequests = fetchMyRequests;

var createOrUpdateMyRequests = function createOrUpdateMyRequests(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [{}];
      resolve({
        ok: true,
        data: data
      });
    }, 500);
  });
};

exports.createOrUpdateMyRequests = createOrUpdateMyRequests;