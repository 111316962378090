"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "App", {
  enumerable: true,
  get: function get() {
    return _App.App;
  }
});
Object.defineProperty(exports, "ACProvider", {
  enumerable: true,
  get: function get() {
    return _provider.ACProvider;
  }
});
Object.defineProperty(exports, "serviceContext", {
  enumerable: true,
  get: function get() {
    return _serviceContext.serviceContext;
  }
});
Object.defineProperty(exports, "serviceContextCloudBatch", {
  enumerable: true,
  get: function get() {
    return _serviceContext.serviceContextCloudBatch;
  }
});
Object.defineProperty(exports, "isMock", {
  enumerable: true,
  get: function get() {
    return _serviceContext.isMock;
  }
});
Object.defineProperty(exports, "Carrier", {
  enumerable: true,
  get: function get() {
    return _carrier.Carrier;
  }
});
Object.defineProperty(exports, "Product", {
  enumerable: true,
  get: function get() {
    return _product.Product;
  }
});
Object.defineProperty(exports, "ACBox", {
  enumerable: true,
  get: function get() {
    return _basic.ACBox;
  }
});
Object.defineProperty(exports, "ACHeader", {
  enumerable: true,
  get: function get() {
    return _basic.ACHeader;
  }
});
Object.defineProperty(exports, "ACHeaderContent", {
  enumerable: true,
  get: function get() {
    return _basic.ACHeaderContent;
  }
});
Object.defineProperty(exports, "ACRow", {
  enumerable: true,
  get: function get() {
    return _basic.ACRow;
  }
});
Object.defineProperty(exports, "ACContent", {
  enumerable: true,
  get: function get() {
    return _basic.ACContent;
  }
});
Object.defineProperty(exports, "ACButton", {
  enumerable: true,
  get: function get() {
    return _basic.ACButton;
  }
});
Object.defineProperty(exports, "ACLayout", {
  enumerable: true,
  get: function get() {
    return _basic.ACLayout;
  }
});
Object.defineProperty(exports, "AC_CONSTANTS", {
  enumerable: true,
  get: function get() {
    return _utils.AC_CONSTANTS;
  }
});

var _moment = _interopRequireDefault(require("moment"));

require("moment-timezone");

require("react-app-polyfill/ie11");

require("core-js/features/array/find");

require("core-js/features/array/includes");

require("core-js/features/number/is-nan");

var _App = require("./app/App");

var _provider = require("./provider");

var _awsSdk = _interopRequireDefault(require("aws-sdk"));

var _awsConfig = require("./awsConfig");

var _serviceContext = require("./provider/serviceContext");

var _carrier = require("./carrier");

var _product = require("./product");

var _basic = require("./basic");

var _utils = require("./utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

// eslint-disable-next-line no-unused-vars

/* polyfills.js */

/* polyfills.js */
Number.isInteger = Number.isInteger || function (value) {
  return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
};

(0, _awsConfig.awsInit)(); // //TODO Yogi
// window.moment = moment;
// initApp();
// //TODO Yogi