"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "RegisterPage", {
  enumerable: true,
  get: function get() {
    return _RegisterPage.RegisterPage;
  }
});

var _RegisterPage = require("./RegisterPage");