"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AGENT_MODE = void 0;
var AGENT_MODE = {
  OUTBOUND: 'OUTBOUND',
  INBOUND: 'INBOUND',
  CALLCENTER: 'CALLCENTER'
};
exports.AGENT_MODE = AGENT_MODE;