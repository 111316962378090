"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AC_CONSTANTS = void 0;

var _gender = require("./constants/gender");

var _menu = require("./constants/menu");

var _language = require("./constants/language");

var _states = require("./constants/states");

var _activityStatus = require("./constants/activityStatus");

var _mode = require("./constants/mode");

var _pageIds = require("./constants/pageIds");

/* eslint-disable no-useless-escape */
// import { DISPOSITION } from './constants/disposition';
var AC_CONSTANTS = {
  GENDER_LIST: _gender.GENDER_LIST,
  MENU_LIST_AGENT: _menu.MENU_LIST_AGENT,
  LANGUAGES: _language.LANGUAGES,
  US_STATES: _states.US_STATES,
  // DISPOSITION,
  AGENT_MODE: _mode.AGENT_MODE,
  ACTIVITY_STATUS: _activityStatus.ACTIVITY_STATUS,
  AC_DATE_FORMAT: 'DD-MMM-YYYY',
  // Display Date on UI
  AC_DATE_TIME_FORMAT: 'DD-MMM-YYYY HH:mm:ss',
  // Display Date Time on UI
  AC_DATE_FORMAT_SERVICE: 'YYYY-MM-DD HH:mm:ss',
  // used to save & retrieve date/time from serviec
  AC_ONLY_DATE_FORMAT_SERVICE: 'YYYY-MM-DD 00:00:00',
  // used to save & retrieve date from serviec
  AC_PHONE_PLACEHOLDER: '(***) ***-****',
  AC_ONLY_NUMBER: /^[0-9\b]+$/,
  AC_ONLY_DECIMAL_NUMBER: /^\s*-?(\d+(\.\d{0,2})?|\.\d{0,2})\s*$/,
  AC_ONLY_ALPHABETS_OLD: /^[A-Z]+$/i,
  AC_ONLY_ALPHABETS: /^[A-Z\s\.\-]+$/i,
  // \s for space  \- for hypen \. for dot \/ for slash
  AC_EMAIL_REGEX: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  AC_ONLY_ALPHA_NUMERIC: /^[a-z0-9]+$/i,
  AC_ONLY_ALPHA_NUMERIC_WITH_SPACE_HYPEN: /^[a-z0-9\s\-]+$/i,
  PAGE_ID: _pageIds.PAGE_ID,
  // HARDCODING
  HARDCODING_clientId: 1,
  HARDCODING_sourceId: 1,
  HARDCODING_campaignId: 1,
  HARDCODING_SOLD: 'Win' // HARDCODING

};
exports.AC_CONSTANTS = AC_CONSTANTS;