"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PAGE_ID = void 0;
var PAGE_ID = {
  MY_OPPORTUNITIES: 'my-opportunities',
  MY_CLIENTS: 'my-clients',
  AGENT_OPPORTUNITIES: 'agent-opportunities',
  AGENT_CLIENTS: 'agent-clients'
};
exports.PAGE_ID = PAGE_ID;