"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchPolicyTypeMaster = exports.createOrUpdatePolicy = exports.fetchPolicies = void 0;

var _PolicyAction = require("./PolicyAction");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var fetchPolicies = function fetchPolicies(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [{
        policyId: 7,
        customerId: 4,
        customerFname: 'Nuria Elizabeth',
        customerLname: 'Mendozaa',
        customerEmail1: 'nuriaeli@yahoo.com',
        customerPhone1: '0',
        productId: 1,
        productName: 'ACC',
        carrierId: 1,
        clientId: 1,
        agentId: 3,
        agent: 'Agent, Agent',
        applicationId: '1',
        policyYear: 2020,
        productType: 'M',
        productGroupId: 1,
        applicationDate: '2020-08-16 00:00:00',
        marketPlace: 'Y',
        autoPay: 'Y',
        policyTypeId: 1,
        policyType: 'OEP',
        assignedGroupId: 1,
        assignedGroup: 'Memorial',
        memberShip: 'Y',
        hccId: 'hccId',
        effectiveDate: '2020-08-16 00:00:00',
        commissionAmt: null,
        status: null,
        processedBy: null,
        processedDate: null,
        policyAmount: 11.0,
        createdBy: '1',
        createdByName: 'Shri, Admin',
        createdDateTime: '2020-08-16 16:14:21',
        modifiedBy: '1',
        modifiedDateTime: '2020-08-16 22:14:53',
        version: 1,
        isActiveStatus: 'Y'
      }, {
        policyId: 8,
        customerId: 4,
        customerFname: 'Nuria Elizabeth',
        customerLname: 'Mendozaa',
        customerEmail1: 'nuriaeli@yahoo.com',
        customerPhone1: '0',
        productId: 1,
        productName: 'ACC',
        carrierId: 1,
        clientId: 1,
        agentId: 3,
        agent: 'Agent, Agent',
        applicationId: '1',
        policyYear: 2021,
        productType: 'M',
        productGroupId: 1,
        applicationDate: '2020-07-27 00:00:00',
        marketPlace: 'Y',
        autoPay: 'Y',
        policyTypeId: 1,
        policyType: 'OEP',
        assignedGroupId: 1,
        assignedGroup: 'Memorial',
        memberShip: null,
        hccId: 'Test',
        effectiveDate: '2020-08-04 00:00:00',
        commissionAmt: null,
        status: null,
        processedBy: null,
        processedDate: null,
        policyAmount: 10.0,
        createdBy: '1',
        createdByName: 'Shri, Admin',
        createdDateTime: '2020-08-16 21:55:52',
        modifiedBy: '1',
        modifiedDateTime: '2020-08-16 22:01:11',
        version: 1,
        isActiveStatus: 'Y'
      }, {
        policyId: 10,
        customerId: 4,
        customerFname: 'Nuria Elizabeth',
        customerLname: 'Mendozaa',
        customerEmail1: 'nuriaeli@yahoo.com',
        customerPhone1: '0',
        productId: 6,
        productName: 'IND-HMO-Q',
        carrierId: 1,
        clientId: 1,
        agentId: 3,
        agent: 'Agent, Agent',
        applicationId: '1234567',
        policyYear: null,
        productType: 'M',
        productGroupId: 1,
        applicationDate: '2020-08-17 00:00:00',
        marketPlace: 'Y',
        autoPay: 'N',
        policyTypeId: 1,
        policyType: 'OEP',
        assignedGroupId: 1,
        assignedGroup: 'Memorial',
        memberShip: null,
        hccId: null,
        effectiveDate: '2021-01-01 00:00:00',
        commissionAmt: null,
        status: null,
        processedBy: null,
        processedDate: null,
        policyAmount: 600.0,
        createdBy: '1',
        createdByName: 'Shri, Admin',
        createdDateTime: '2020-08-17 14:37:04',
        modifiedBy: null,
        modifiedDateTime: null,
        version: 1,
        isActiveStatus: 'Y'
      }];
      resolve({
        ok: true,
        data: data
      });
    }, 500);
  });
};

exports.fetchPolicies = fetchPolicies;

var createOrUpdatePolicy = function createOrUpdatePolicy(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [_objectSpread({
        policyId: 1,
        policyYear: 2020
      }, params)];
      resolve({
        ok: true,
        data: data
      });
    }, 500);
  });
};

exports.createOrUpdatePolicy = createOrUpdatePolicy;

var fetchPolicyTypeMaster = function fetchPolicyTypeMaster(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [{
        policyTypeId: 1,
        policyType: 'OEP',
        createdBy: 'user1',
        createdDateTime: '2020-08-11 18:03:31',
        modifiedBy: 'user1',
        modifiedDateTime: '2020-08-11 18:05:19',
        isActiveStatus: 'Y'
      }, {
        policyTypeId: 4,
        policyType: 'SEP',
        createdBy: 'nishtha',
        createdDateTime: '2020-08-11 19:34:27',
        modifiedBy: 'nishtha',
        modifiedDateTime: '2020-08-11 19:34:27',
        isActiveStatus: 'Y'
      }, {
        policyTypeId: 5,
        policyType: 'SEP',
        createdBy: '456',
        createdDateTime: '2020-08-14 09:50:17',
        modifiedBy: '456',
        modifiedDateTime: '2020-08-14 09:50:17',
        isActiveStatus: 'Y'
      }];
      resolve({
        ok: true,
        data: data
      });
      (0, _PolicyAction.setPolicyTypeMaster)({
        policyTypeLoading: false,
        error: false,
        errorMsg: '',
        data: [].concat(data)
      });
    }, 500);
  });
};

exports.fetchPolicyTypeMaster = fetchPolicyTypeMaster;