"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ColumnDefs = void 0;

var _datagrid = require("../../../../datagrid");

var ColumnDefs = [// {
//   lockPosition: true,
//   headerCheckboxSelection: true,
//   headerCheckboxSelectionFilteredOnly: true,
//   checkboxSelection: true,
//   minWidth: 55,
//   maxWidth: 55,
//   filter: false
// },
{
  field: 'agentName',
  headerName: 'Agent Name'
}, {
  field: 'aor',
  headerName: 'AOR'
}, {
  field: 'action',
  headerName: 'Action'
}, {
  field: 'reason',
  headerName: 'Reason'
}, {
  field: 'customerName',
  headerName: 'Name'
}, {
  field: 'county',
  headerName: 'County'
}, {
  field: 'zip',
  headerName: 'Zip Code'
}, {
  field: 'dobGrid',
  headerName: 'Date of Birth',
  comparator: _datagrid.ACDateComparatorSorting,
  filter: 'agDateColumnFilter',
  filterParams: {
    buttons: ['apply', 'reset'],
    closeOnApply: true,
    suppressAndOrCondition: true,
    comparator: _datagrid.ACDateComparatorFiltering,
    browserDatePicker: true
  }
}, {
  field: 'followUpDateGrid',
  headerName: 'Follow Up Date',
  comparator: _datagrid.ACDateComparatorSorting,
  filter: 'agDateColumnFilter',
  filterParams: {
    buttons: ['apply', 'reset'],
    closeOnApply: true,
    suppressAndOrCondition: true,
    comparator: _datagrid.ACDateComparatorFiltering,
    browserDatePicker: true
  }
}, {
  field: 'phoneNumber',
  headerName: 'Phone Number'
}, {
  field: 'email',
  headerName: 'Email'
}, {
  field: 'source',
  headerName: 'Source'
}, {
  field: 'campaignName',
  headerName: 'Campaign'
}, {
  field: 'opportunityCreatedDateGrid',
  headerName: 'Created Date',
  comparator: _datagrid.ACDateComparatorSorting,
  filter: 'agDateColumnFilter',
  filterParams: {
    buttons: ['apply', 'reset'],
    closeOnApply: true,
    suppressAndOrCondition: true,
    comparator: _datagrid.ACDateComparatorFiltering,
    browserDatePicker: true
  }
}];
exports.ColumnDefs = ColumnDefs;