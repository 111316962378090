"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setNotes = exports.useNotes = exports.setUse = exports.useUse = exports.setTasks = exports.useTasks = exports.setCont = exports.useCont = exports.setAccount = exports.useAccount = exports.setMyActivities = exports.useMyActivities = void 0;

var _hooksForRedux = require("../../provider/hooks-for-redux");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var _useRedux = (0, _hooksForRedux.useRedux)('myactivities', {
  myactivitiesLoading: false,
  error: false,
  errorMsg: '',
  rowData: [],
  selectedMyActivities: null
}, {
  setMyActivities: function setMyActivities(oldState, newState) {
    return _objectSpread(_objectSpread({}, oldState), newState);
  }
}),
    _useRedux2 = _slicedToArray(_useRedux, 2),
    useMyActivities = _useRedux2[0],
    setMyActivities = _useRedux2[1].setMyActivities;

exports.setMyActivities = setMyActivities;
exports.useMyActivities = useMyActivities;

var _useRedux3 = (0, _hooksForRedux.useRedux)('account', {
  accountLoading: false,
  error: false,
  errorMsg: '',
  data: []
}, {
  setAccount: function setAccount(oldState, newState) {
    return _objectSpread(_objectSpread({}, oldState), newState);
  }
}),
    _useRedux4 = _slicedToArray(_useRedux3, 2),
    useAccount = _useRedux4[0],
    setAccount = _useRedux4[1].setAccount;

exports.setAccount = setAccount;
exports.useAccount = useAccount;

var _useRedux5 = (0, _hooksForRedux.useRedux)('cont', {
  contLoading: false,
  error: false,
  errorMsg: '',
  data: []
}, {
  setCont: function setCont(oldState, newState) {
    return _objectSpread(_objectSpread({}, oldState), newState);
  }
}),
    _useRedux6 = _slicedToArray(_useRedux5, 2),
    useCont = _useRedux6[0],
    setCont = _useRedux6[1].setCont;

exports.setCont = setCont;
exports.useCont = useCont;

var _useRedux7 = (0, _hooksForRedux.useRedux)('tasks', {
  tasksLoading: false,
  error: false,
  errorMsg: '',
  data: []
}, {
  setTasks: function setTasks(oldState, newState) {
    return _objectSpread(_objectSpread({}, oldState), newState);
  }
}),
    _useRedux8 = _slicedToArray(_useRedux7, 2),
    useTasks = _useRedux8[0],
    setTasks = _useRedux8[1].setTasks;

exports.setTasks = setTasks;
exports.useTasks = useTasks;

var _useRedux9 = (0, _hooksForRedux.useRedux)('use', {
  useLoading: false,
  error: false,
  errorMsg: '',
  data: []
}, {
  setUse: function setUse(oldState, newState) {
    return _objectSpread(_objectSpread({}, oldState), newState);
  }
}),
    _useRedux10 = _slicedToArray(_useRedux9, 2),
    useUse = _useRedux10[0],
    setUse = _useRedux10[1].setUse;

exports.setUse = setUse;
exports.useUse = useUse;

var _useRedux11 = (0, _hooksForRedux.useRedux)('notes', {
  notesLoading: false,
  error: false,
  errorMsg: '',
  data: []
}, {
  setNotes: function setNotes(oldState, newState) {
    return _objectSpread(_objectSpread({}, oldState), newState);
  }
}),
    _useRedux12 = _slicedToArray(_useRedux11, 2),
    useNotes = _useRedux12[0],
    setNotes = _useRedux12[1].setNotes;

exports.setNotes = setNotes;
exports.useNotes = useNotes;