"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ColumnDefs = void 0;

var _datagrid = require("../../../../datagrid");

var ColumnDefs = [{
  lockPosition: true,
  headerCheckboxSelection: true,
  headerCheckboxSelectionFilteredOnly: true,
  checkboxSelection: true,
  minWidth: 55,
  maxWidth: 55,
  filter: false
}, {
  field: 'leadCreatedDateTimeGrid',
  headerName: 'Date Added',
  comparator: _datagrid.ACDateComparatorSorting,
  filter: 'agDateColumnFilter',
  filterParams: {
    buttons: ['apply', 'reset'],
    closeOnApply: true,
    suppressAndOrCondition: true,
    comparator: _datagrid.ACDateComparatorFiltering,
    browserDatePicker: true
  }
}, {
  field: 'firstUserName',
  headerName: 'Assigned Agent Name'
}, {
  headerName: 'Name',
  valueGetter: function valueGetter(params) {
    return params.data.customerFname + ' ' + params.data.customerLname;
  }
}, {
  field: 'leadStatus',
  headerName: 'Status',
  cellRenderer: 'statusRenderer'
}, {
  field: 'currentPolicyList',
  headerName: 'Policies'
}, {
  field: 'source',
  headerName: 'Source',
  // suppressMovable: true, // lockPosition: true,
  valueGetter: function valueGetter(params) {
    return params.data.sourceLevel1 + params.data.sourceLevel2;
  }
}, {
  field: 'campaignName',
  headerName: 'Campaign'
}, {
  field: 'dobGrid',
  headerName: 'DOB',
  comparator: _datagrid.ACDateComparatorSorting,
  filter: 'agDateColumnFilter',
  filterParams: {
    buttons: ['apply', 'reset'],
    closeOnApply: true,
    suppressAndOrCondition: true,
    comparator: _datagrid.ACDateComparatorFiltering,
    browserDatePicker: true
  }
}, {
  field: 'phone1',
  headerName: 'Contact'
}, {
  field: 'email1',
  headerName: 'Email'
}, {
  field: 'lastDispositionGrid',
  headerName: 'Last Contacted',
  comparator: _datagrid.ACDateComparatorSorting,
  filter: 'agDateColumnFilter',
  filterParams: {
    buttons: ['apply', 'reset'],
    closeOnApply: true,
    suppressAndOrCondition: true,
    comparator: _datagrid.ACDateComparatorFiltering,
    browserDatePicker: true
  }
}, {
  field: 'leadAssignedDateGrid',
  headerName: 'Date Assigned',
  comparator: _datagrid.ACDateComparatorSorting,
  filter: 'agDateColumnFilter',
  filterParams: {
    buttons: ['apply', 'reset'],
    closeOnApply: true,
    suppressAndOrCondition: true,
    comparator: _datagrid.ACDateComparatorFiltering,
    browserDatePicker: true
  }
}, {
  field: 'lastDisposition',
  headerName: 'Last Disposition'
}, {
  field: 'firstUserId',
  headerName: 'Assigned Agent Id'
}, {
  field: 'leadId',
  headerName: 'CRM ID(Testing)'
}];
exports.ColumnDefs = ColumnDefs;