"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createOrUpdateBroadcast = exports.fetchBroadcast = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _BroadcastAction = require("./BroadcastAction");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var fetchBroadcast = function fetchBroadcast(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [{
        messageId: 2,
        createdBy: '23',
        status: 'N',
        isActiveStatus: 'Y',
        message: 'Call your delinquent clients today',
        version: 4,
        modifiedBy: '1',
        messagePriority: 'H',
        createdDate: '2020-09-20 23:34:53',
        modifiedDate: '2020-10-15 17:44:34',
        messageStartDate: '2020-09-20 04:00:00',
        messageEndDate: '2030-09-30 04:00:00' //   messageId: 14,
        //   createdBy: 'user1',
        //   isActiveStatus: 'N',
        //   message: 'broadcast message eight',
        //   version: 0,
        //   modifiedBy: 'user1',
        //   messagePriority: 'M',
        //   createdDate: '2020-08-08 07:02:59',
        //   modifiedDate: '2020-08-08 07:02:59',
        //   messageStartDate: '2020-08-08 06:26:48',
        //   messageEndDate: '2020-08-11 11:26:48'
        // },
        // {
        //   messageId: 15,
        //   createdBy: 'user1',
        //   isActiveStatus: 'Y',
        //   message: 'broadcast message eight',
        //   version: 0,
        //   modifiedBy: 'user1',
        //   messagePriority: 'M',
        //   createdDate: '2020-08-08 07:29:09',
        //   modifiedDate: '2020-08-08 07:29:09',
        //   messageStartDate: '2020-08-08 06:26:48',
        //   messageEndDate: '2020-08-11 11:26:48'
        // },
        // {
        //   messageId: 16,
        //   createdBy: 'user1',
        //   isActiveStatus: 'Y',
        //   message: 'broadcast message eight',
        //   version: 0,
        //   modifiedBy: 'user1',
        //   messagePriority: 'M',
        //   createdDate: '2020-08-08 07:30:28',
        //   modifiedDate: '2020-08-08 07:30:28',
        //   messageStartDate: '2020-08-08 06:26:48',
        //   messageEndDate: '2020-08-11 11:26:48'
        // },
        // {
        //   messageId: 17,
        //   createdBy: 'user1',
        //   isActiveStatus: 'Y',
        //   message: 'broadcast message eight',
        //   version: 0,
        //   modifiedBy: 'user1',
        //   messagePriority: 'M',
        //   createdDate: '2020-08-08 07:33:04',
        //   modifiedDate: '2020-08-08 07:33:04',
        //   messageStartDate: '2020-08-08 06:26:48',
        //   messageEndDate: '2020-08-11 11:26:48'
        // },
        // {
        //   messageId: 18,
        //   createdBy: 'user1',
        //   isActiveStatus: 'Y',
        //   message: 'broadcast message eight',
        //   version: 0,
        //   modifiedBy: 'user1',
        //   messagePriority: 'M',
        //   createdDate: '2020-08-08 07:33:48',
        //   modifiedDate: '2020-08-08 07:33:48',
        //   messageStartDate: '2020-08-08 06:26:48',
        //   messageEndDate: '2020-08-11 11:26:48'

      }];
      resolve({
        ok: true,
        data: data
      });
    }, 500);
  });
};

exports.fetchBroadcast = fetchBroadcast;

var createOrUpdateBroadcast = function createOrUpdateBroadcast(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [_objectSpread({
        messageId: 19,
        createdBy: 'user1',
        isActiveStatus: 'Y',
        message: 'broadcast message eight',
        version: 0,
        modifiedBy: 'user1',
        messagePriority: 'M',
        createdDate: '2020-08-08 07:40:23',
        modifiedDate: '2020-08-08 07:40:23',
        messageStartDate: '2020-08-08 06:26:48',
        messageEndDate: '2020-08-11 11:26:48'
      }, params)];
      resolve({
        ok: true,
        data: data
      });
    }, 500);
  });
};

exports.createOrUpdateBroadcast = createOrUpdateBroadcast;