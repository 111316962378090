"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getExportedFiles = getExportedFiles;
exports.downloadFile = downloadFile;

var _awsSdk = _interopRequireDefault(require("aws-sdk"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var awsConfig = {
  bucketName: '',
  prefix: 'outbound_lead_files',
  region: 'us-east-2'
};

function getExportedFiles(bucketName) {
  awsConfig.bucketName = bucketName;
  var s3 = new _awsSdk["default"].S3({
    apiVersion: '2006-03-01',
    params: {
      Bucket: awsConfig.bucketName,
      Prefix: awsConfig.prefix
    }
  });
  return new Promise(function (resolve, reject) {
    s3.listObjectsV2({}, function (err, data) {
      if (err) {
        reject(err);
      } else {
        var files = [];
        var today = new Date();
        var dayOneMonthBack = new Date();
        dayOneMonthBack.setDate(dayOneMonthBack.getDate() - 30);
        data.Contents.forEach(function (content) {
          var fileName = content.Key.split(awsConfig.prefix + '/')[1];

          if (fileName && fileName != '') {
            var fileType = fileName.split('.')[1];
            var fileSize = content.Size;
            var modifiedDate = content.LastModified;
            var fileObj = {
              fileName: fileName,
              fileType: fileType,
              fileSize: fileSize,
              modifiedDate: modifiedDate
            };

            if (modifiedDate > dayOneMonthBack && modifiedDate < today) {
              files.push(fileObj);
            }
          }
        });
        files.sort(function (a, b) {
          var dateA = a.modifiedDate,
              dateB = b.modifiedDate;
          if (dateA < dateB) return 1;
          if (dateA > dateB) return -1;
          return 0;
        });
        resolve(files);
      }
    });
  });
}

function downloadFile(Key) {
  var url = '';
  var s3 = new _awsSdk["default"].S3({
    apiVersion: '2006-03-01',
    params: {
      Bucket: awsConfig.bucketName,
      Prefix: awsConfig.prefix
    }
  }); //  return new Promise((resolve, reject) => {

  s3.getSignedUrl('getObject', {
    Bucket: awsConfig.bucketName,
    Key: awsConfig.prefix + '/' + Key
  }, function (error, data) {
    if (error) {
      console.log(error);
      url = '';
    } else {
      console.log('URL ', data);
      window.open(data);
      url = data;
    }
  });
  return url;
}