"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ACDateComparatorFiltering = ACDateComparatorFiltering;
exports.ACDateComparatorSorting = ACDateComparatorSorting;
exports.ACDateTimeComparatorSorting = ACDateTimeComparatorSorting;

var _moment = _interopRequireDefault(require("moment"));

var _utils = require("../utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ACDateComparatorFiltering(filterLocalDateAtMidnight, cellValue) {
  var dateAsString = cellValue;

  if (dateAsString === null) {
    return 0;
  } // In the example application, dates are stored as dd/mm/yyyy
  // We create a Date object for comparison against the filter date


  var cellDate = (0, _moment["default"])(dateAsString, _utils.AC_CONSTANTS.AC_DATE_FORMAT).toDate(); // Now that both parameters are Date objects, we can compare

  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  } else if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  } else {
    return 0;
  }
}

function ACDateComparatorSorting(date1, date2) {
  if (date1 === null && date2 === null) {
    return 0;
  }

  var date1Number = (0, _moment["default"])(date1, _utils.AC_CONSTANTS.AC_DATE_FORMAT).toDate();
  var date2Number = (0, _moment["default"])(date2, _utils.AC_CONSTANTS.AC_DATE_FORMAT).toDate();

  if (date1Number < date2Number) {
    return -1;
  } else if (date1Number > date2Number) {
    return 1;
  } else {
    return 0;
  }
} // browserDatePicker doesn't have time part to it. It can only filter date
// export function ACDateTimeComparatorFiltering(
//   filterLocalDateAtMidnight,
//   cellValue
// ) {
//   const dateAsString = cellValue;
//   if (dateAsString === null) {
//     return 0;
//   }
//   // In the example application, dates are stored as dd/mm/yyyy
//   // We create a Date object for comparison against the filter date
//   const cellDate = moment(
//     dateAsString,
//     AC_CONSTANTS.AC_DATE_TIME_FORMAT
//   ).toDate();
//   // Now that both parameters are Date objects, we can compare
//   if (cellDate < filterLocalDateAtMidnight) {
//     return -1;
//   } else if (cellDate > filterLocalDateAtMidnight) {
//     return 1;
//   } else {
//     return 0;
//   }
// }


function ACDateTimeComparatorSorting(date1, date2) {
  if (date1 === null && date2 === null) {
    return 0;
  }

  var date1Number = (0, _moment["default"])(date1, _utils.AC_CONSTANTS.AC_DATE_TIME_FORMAT).toDate();
  var date2Number = (0, _moment["default"])(date2, _utils.AC_CONSTANTS.AC_DATE_TIME_FORMAT).toDate();

  if (date1Number < date2Number) {
    return -1;
  } else if (date1Number > date2Number) {
    return 1;
  } else {
    return 0;
  }
}