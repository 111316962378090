"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addDefaultActivities = exports.createOrUpdateActivity = exports.updateActivityAction = exports.fetchCallLogs = exports.fetchActivities = void 0;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var fetchActivities = function fetchActivities() {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [{
        activityId: 44,
        activityName: 'Reviewed Personal Details',
        activityDesc: 'Reviewed Personal Details',
        activityStatus: 'NEW',
        version: 1,
        isActiveStatus: 'Y',
        leadId: 1,
        customerId: 1,
        createdBy: '1',
        modifiedBy: null,
        modifiedDateTime: '2020-07-18T17:55:44.000+00:00',
        createdDateTime: '2020-07-18T17:55:44.000+00:00'
      }, {
        activityId: 45,
        activityName: 'Reviewed Contact Details',
        activityDesc: 'Reviewed Contact Details',
        activityStatus: 'NEW',
        version: 1,
        isActiveStatus: 'Y',
        leadId: 1,
        customerId: 1,
        createdBy: '1',
        modifiedBy: null,
        modifiedDateTime: '2020-07-18T17:55:45.000+00:00',
        createdDateTime: '2020-07-18T17:55:45.000+00:00'
      }, {
        activityId: 46,
        activityName: 'Reviewed/Added Dependents',
        activityDesc: 'Reviewed Dependents Details',
        activityStatus: 'NEW',
        version: 1,
        isActiveStatus: 'Y',
        leadId: 1,
        customerId: 1,
        createdBy: '1',
        modifiedBy: null,
        modifiedDateTime: '2020-07-18T17:55:46.000+00:00',
        createdDateTime: '2020-07-18T17:55:46.000+00:00'
      }, {
        activityId: 47,
        activityName: 'Reviewed Polices Details',
        activityDesc: 'Reviewed Polices Details',
        activityStatus: 'NEW',
        version: 1,
        isActiveStatus: 'Y',
        leadId: 1,
        customerId: 1,
        createdBy: '1',
        modifiedBy: null,
        modifiedDateTime: '2020-07-18T17:55:47.000+00:00',
        createdDateTime: '2020-07-18T17:55:47.000+00:00'
      }];
      resolve({
        ok: true,
        data: data
      });
    }, 500);
  });
};

exports.fetchActivities = fetchActivities;

var fetchCallLogs = function fetchCallLogs() {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [];
      resolve({
        ok: true,
        data: data
      });
    }, 500);
  });
};

exports.fetchCallLogs = fetchCallLogs;

var updateActivityAction = function updateActivityAction(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [{
        activityId: params.activityId,
        activityName: 'Reviewed Polices Details',
        activityDesc: 'Reviewed Polices Details',
        activityStatus: params.activityStatus,
        version: 1,
        isActiveStatus: 'Y',
        leadId: 1,
        customerId: 1,
        createdBy: '1',
        modifiedBy: null,
        modifiedDateTime: '2020-07-18T17:55:47.000+00:00',
        createdDateTime: '2020-07-18T17:55:47.000+00:00'
      }];
      resolve({
        ok: true,
        data: data
      });
    }, 500);
  });
};

exports.updateActivityAction = updateActivityAction;

var createOrUpdateActivity = function createOrUpdateActivity(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [_objectSpread({
        activityId: params.activityId,
        activityName: 'Reviewed Polices Details',
        activityDesc: 'Reviewed Polices Details',
        activityStatus: params.activityStatus,
        version: 1,
        isActiveStatus: 'Y',
        leadId: 1,
        customerId: 1,
        createdBy: '1',
        modifiedBy: null,
        modifiedDateTime: '2020-07-18T17:55:47.000+00:00',
        createdDateTime: '2020-07-18T17:55:47.000+00:00'
      }, params)];
      resolve({
        ok: true,
        data: data
      });
    }, 500);
  });
};

exports.createOrUpdateActivity = createOrUpdateActivity;

var addDefaultActivities = function addDefaultActivities(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [_objectSpread({
        activityId: 50,
        activityName: 'Reviewed Personal Details',
        activityDesc: 'Reviewed Personal Details',
        activityStatus: 'NEW',
        version: 1,
        isActiveStatus: 'Y',
        leadId: 2,
        customerId: 2,
        createdBy: '1',
        modifiedBy: null,
        modifiedDateTime: 1595192449447,
        createdDateTime: 1595192449447
      }, params), _objectSpread({
        activityId: 51,
        activityName: 'Reviewed Contact Details',
        activityDesc: 'Reviewed Contact Details',
        activityStatus: 'NEW',
        version: 1,
        isActiveStatus: 'Y',
        leadId: 2,
        customerId: 2,
        createdBy: '1',
        modifiedBy: null,
        modifiedDateTime: 1595192452162,
        createdDateTime: 1595192452162
      }, params), _objectSpread({
        activityId: 52,
        activityName: 'Reviewed/Added Dependents',
        activityDesc: 'Reviewed Dependents Details',
        activityStatus: 'NEW',
        version: 1,
        isActiveStatus: 'Y',
        leadId: 2,
        customerId: 2,
        createdBy: '1',
        modifiedBy: null,
        modifiedDateTime: 1595192453246,
        createdDateTime: 1595192453246
      }, params), _objectSpread({
        activityId: 53,
        activityName: 'Reviewed Polices Details',
        activityDesc: 'Reviewed Polices Details',
        activityStatus: 'NEW',
        version: 1,
        isActiveStatus: 'Y',
        leadId: 2,
        customerId: 2,
        createdBy: '1',
        modifiedBy: null,
        modifiedDateTime: 1595192454326,
        createdDateTime: 1595192454326
      }, params)];
      resolve({
        ok: true,
        data: data
      });
    }, 500);
  });
};

exports.addDefaultActivities = addDefaultActivities;