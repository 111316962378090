"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createOrUpdateActivity = exports.fetchActivity = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _ActivityAction = require("./ActivityAction");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var fetchActivity = function fetchActivity(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [{}];
      resolve({
        ok: true,
        data: data
      });
    }, 500);
  });
};

exports.fetchActivity = fetchActivity;

var createOrUpdateActivity = function createOrUpdateActivity(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [{}];
      resolve({
        ok: true,
        data: data
      });
    }, 500);
  });
};

exports.createOrUpdateActivity = createOrUpdateActivity;