"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createOrUpdateCompanyAccount = exports.fetchCompanyAccount = void 0;

var _basic = require("../../basic");

var _lodash = _interopRequireDefault(require("lodash"));

var _moment = _interopRequireDefault(require("moment"));

var _uuid = require("uuid");

var _utils = require("../../utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var fetchCompanyAccount = function fetchCompanyAccount(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [{
        accountId: 2,
        accountName: 'Shri',
        add1: 'USA, texas',
        add2: null,
        city: null,
        county: null,
        state: null,
        zip: null,
        country: null,
        fax: null,
        primaryEmail: 'shri_test',
        primaryPhone: 878787,
        primaryPhoneExt: null,
        secondaryEmail: null,
        secondaryPhone: null,
        secondaryPhoneExt: null,
        primaryContact: 2,
        isActiveStatus: 'Y',
        createdBy: 1,
        createdDate: '2021-03-18 17:51:15',
        modifiedBy: null,
        modifiedDate: null,
        version: 1
      }];
      resolve({
        ok: true,
        data: data
      });
    }, 500);
  });
};

exports.fetchCompanyAccount = fetchCompanyAccount;

var createOrUpdateCompanyAccount = function createOrUpdateCompanyAccount(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [{
        accountId: 2,
        accountName: 'Shri',
        add1: 'USA, texas',
        add2: null,
        city: null,
        county: null,
        state: null,
        zip: null,
        country: null,
        fax: null,
        primaryEmail: 'shri_test',
        primaryPhone: 878787,
        primaryPhoneExt: null,
        secondaryEmail: null,
        secondaryPhone: null,
        secondaryPhoneExt: null,
        primaryContact: 2,
        isActiveStatus: 'Y',
        createdBy: 1,
        createdDate: '2021-03-18 17:51:15',
        modifiedBy: null,
        modifiedDate: null,
        version: 1
      }];
      resolve({
        ok: true,
        data: data
      });
    }, 500);
  });
};

exports.createOrUpdateCompanyAccount = createOrUpdateCompanyAccount;