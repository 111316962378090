"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isMock = exports.serviceContextCloudBatch = exports.serviceContext = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _lodash = _interopRequireDefault(require("lodash"));

var _hooksForRedux = require("./hooks-for-redux");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

// let serviceContext = 'http://localhost:8080/agencyCloud';
//let serviceContext = 'http://18.217.26.69:8080/synergite'; changed
//UAT let serviceContext='http://ec2-3-133-128-24.us-east-2.compute.amazonaws.com:8080/synergite';
var serviceContext = 'http://18.217.26.69:8080/synergite'; // serviceContext = 'http://ec2-3-22-217-53.us-east-2.compute.amazonaws.com:8080/agencyCloud'
// serviceContext = 'http://ec2-3-135-200-53.us-east-2.compute.amazonaws.com:8080/agencyCloud';
// serviceContext = 'http://ec2-18-191-77-37.us-east-2.compute.amazonaws.com:8080/agencyCloud';
//let serviceContextCloudBatch = 'http://18.217.26.69:8080/synergiteBatch'; changed
//UAT let serviceContextCloudBatch = 'http://ec2-3-133-128-24.us-east-2.compute.amazonaws.com:8080/synergiteBatch';

exports.serviceContext = serviceContext;
var serviceContextCloudBatch = 'http://18.217.26.69:8080/synergiteBatch'; //DEV with static ip
//Existing
// serviceContext = 'http://18.217.26.69:8080/agencyCloud'; //DEV
// serviceContextCloudBatch = 'http://18.217.26.69:8080/agencyCloudBatch'; //DEV
//New
//serviceContext = 'http://18.217.26.69:8080/synergite'; Changed
//serviceContextCloudBatch = 'http://18.217.26.69:8080/synergiteBatch'; Changed
//UAT
//serviceContext='http://ec2-3-133-128-24.us-east-2.compute.amazonaws.com:8080/synergite';
//serviceContextCloudBatch = 'http://ec2-3-133-128-24.us-east-2.compute.amazonaws.com:8080/synergiteBatch';

exports.serviceContextCloudBatch = serviceContextCloudBatch;
exports.serviceContext = serviceContext = 'http://18.217.26.69:8080/synergite';
exports.serviceContextCloudBatch = serviceContextCloudBatch = 'http://18.217.26.69:8080/synergiteBatch'; // //DEV
// serviceContext =
//   'http://ec2-18-217-26-69.us-east-2.compute.amazonaws.com:8080/agencyCloud'; //DEV
// serviceContextCloudBatch =
//   'http://ec2-18-217-26-69.us-east-2.compute.amazonaws.com:8080/agencyCloudBatch'; //DEV
// //UAT
// serviceContext =
//   'http://ec2-3-129-249-212.us-east-2.compute.amazonaws.com:8080/agencyCloud'; //UAT
// serviceContextCloudBatch =
//   'http://ec2-3-129-249-212.us-east-2.compute.amazonaws.com:8080/agencyCloudBatch'; //UAT
// //PROD
// serviceContext =
//   'http://ec2-3-22-204-95.us-east-2.compute.amazonaws.com:8080/agencyCloud'; //PROD
// serviceContextCloudBatch =
//   'http://ec2-3-22-204-95.us-east-2.compute.amazonaws.com:8080/agencyCloudBatch'; //PROD

var headers = {
  userId: 'shri'
}; // headers = { userId: 'yogi', Pragma: 'no-cache' };
// headers = { userId: 1, Pragma: 'no-cache' };

headers = {}; // axios.defaults.headers.common['Pragma'] = 'no-cache';
// axios.defaults.headers.common['Content-Type'] = 'application/json';
// Add a request interceptor

_axios["default"].interceptors.request.use(function (config) {
  // const token = store.getState().session.token;
  // config.headers.Authorization =  token;
  var store = (0, _hooksForRedux.getStore)().getState();

  if (_lodash["default"].has(store, 'user.data')) {
    var currentUser = store.user.data[0] || {
      userId: 1
    };
    config.headers.userId = currentUser.userId;
  }

  return config;
});

var isMock = false;
exports.isMock = isMock;