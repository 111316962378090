"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AppsetupPage", {
  enumerable: true,
  get: function get() {
    return _AppsetupPage.AppsetupPage;
  }
});

var _AppsetupPage = require("./AppsetupPage");