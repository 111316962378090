"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MENU_LIST_AGENT = void 0;
var MENU_LIST_AGENT = [{
  menuId: '1',
  menuLinkKey: 'MY_ACTIVE_LEADS'
}, {
  menuId: '2',
  menuLinkKey: 'MY_COMPLETED_LEADS'
}, {
  menuId: '3',
  menuLinkKey: 'MY_NEW_LEADS'
}];
exports.MENU_LIST_AGENT = MENU_LIST_AGENT;