"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ColumnDefs = void 0;

var _datagrid = require("../../../../datagrid");

var ColumnDefs = [{
  headerName: 'Type',
  colId: 'Name' // valueGetter: function (params) {
  //   return params.data.customerFname + ' ' + params.data.customerLname;
  // }

}, {
  // field: 'phone1',
  colId: 'phone1',
  headerName: 'Sub Type'
}, {
  // field: 'leadStatus',
  colId: 'Status',
  headerName: 'Status',
  cellRenderer: 'statusRenderer'
}, {
  // field: 'leadStatus',
  colId: 'substatus',
  headerName: 'Sub-Status',
  cellRenderer: 'statusRenderer'
}, {
  // field: 'dobGrid',
  colId: 'statusdate',
  headerName: 'Status Changed Date',
  comparator: _datagrid.ACDateComparatorSorting,
  filter: 'agDateColumnFilter'
}, {
  headerName: 'Account',
  colId: 'Name'
}, {
  headerName: 'Contact',
  colId: 'Name'
}, {
  headerName: 'Priority',
  colId: 'Name'
}, {
  // field: 'dobGrid',
  colId: 'dobGrid',
  headerName: 'Due Date',
  comparator: _datagrid.ACDateComparatorSorting,
  filter: 'agDateColumnFilter',
  filterParams: {
    buttons: ['apply', 'reset'],
    closeOnApply: true,
    suppressAndOrCondition: true,
    comparator: _datagrid.ACDateComparatorFiltering,
    browserDatePicker: true
  }
}, {
  headerName: 'Assigned By',
  colId: 'Name'
}, {
  // field: 'dobGrid',
  colId: 'dobGrid',
  headerName: 'Assigned By Date',
  comparator: _datagrid.ACDateComparatorSorting,
  filter: 'agDateColumnFilter',
  filterParams: {
    buttons: ['apply', 'reset'],
    closeOnApply: true,
    suppressAndOrCondition: true,
    comparator: _datagrid.ACDateComparatorFiltering,
    browserDatePicker: true
  }
} // {
//   field: 'email1',
//   colId: 'email1',
//   headerName: 'Email'
// },
// {
//   headerName: 'Created By',
//   colId: 'Name',
// },
// {
//   // field: 'dobGrid',
//   colId: 'dobGrid',
//   headerName: 'Created By Date',
//   comparator: ACDateComparatorSorting,
//   filter: 'agDateColumnFilter',
//   filterParams: {
//     buttons: ['apply', 'reset'],
//     closeOnApply: true,
//     suppressAndOrCondition: true,
//     comparator: ACDateComparatorFiltering,
//     browserDatePicker: true
//   }
// },
// {
//   headerName: 'Memo',
//   colId: 'Name',
// },
// {
//   headerName: 'Last Updated By',
//   colId: 'Name',
// },
// {
//   // field: 'dobGrid',
//   colId: 'dobGrid',
//   headerName: 'Last Updated Date',
//   comparator: ACDateComparatorSorting,
//   filter: 'agDateColumnFilter',
//   filterParams: {
//     buttons: ['apply', 'reset'],
//     closeOnApply: true,
//     suppressAndOrCondition: true,
//     comparator: ACDateComparatorFiltering,
//     browserDatePicker: true
//   }
// }
// {
//   field: 'activityStatus',
//   colId: 'activityStatus',
//   headerName: 'Activity Status'
// },
// {
//   field: 'activityDueDateGrid',
//   colId: 'activityDueDateGrid',
//   headerName: 'Activity Due Date',
//   minWidth: 200,
//   comparator: ACDateComparatorSorting,
//   filter: 'agDateColumnFilter',
//   filterParams: {
//     buttons: ['apply', 'reset'],
//     closeOnApply: true,
//     suppressAndOrCondition: true,
//     comparator: ACDateComparatorFiltering,
//     browserDatePicker: true
//   }
// },
// {
//   field: 'lastDispositionGrid',
//   colId: 'lastDispositionGrid',
//   headerName: 'Last Contact',
//   comparator: ACDateComparatorSorting,
//   filter: 'agDateColumnFilter',
//   filterParams: {
//     buttons: ['apply', 'reset'],
//     closeOnApply: true,
//     suppressAndOrCondition: true,
//     comparator: ACDateComparatorFiltering,
//     browserDatePicker: true
//   }
// },
// {
//   field: 'lastDisposition',
//   colId: 'lastDisposition',
//   headerName: 'Reason'
// },
// {
//   // field: 'source',
//   colId: 'source',
//   headerName: 'Source',
//   // suppressMovable: true, // lockPosition: true,
//   valueGetter: function (params) {
//     return params.data.sourceLevel1 + params.data.sourceLevel2;
//   }
// },
// {
//   field: 'campaignName',
//   colId: 'campaignName',
//   headerName: 'Campaign'
// },
// {
//   field: 'leadCreatedDateTimeGrid',
//   headerName: 'Date Added',
//   filter: 'agDateColumnFilter',
//   filterParams: {
//     buttons: ['apply', 'reset'],
//     closeOnApply: true,
//     suppressAndOrCondition: true,
//     comparator: ACDateComparatorFiltering,
//     browserDatePicker: true
//   }
// },
// {
//   field: 'leadAssignedDateGrid',
//   colId: 'leadAssignedDateGrid',
//   headerName: 'Date Assigned',
//   comparator: ACDateComparatorSorting,
//   filter: 'agDateColumnFilter',
//   filterParams: {
//     buttons: ['apply', 'reset'],
//     closeOnApply: true,
//     suppressAndOrCondition: true,
//     comparator: ACDateComparatorFiltering,
//     browserDatePicker: true
//   }
// },
// {
//   field: 'currentPolicyList',
//   colId: 'currentPolicyList',
//   headerName: 'Policies'
// },
// {
//   field: 'county',
//   colId: 'county',
//   headerName: 'County'
// }
];
exports.ColumnDefs = ColumnDefs;