"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetConsentToContact = exports.fetchLeadByInbound = exports.fetchLeadById = exports.saveLead = exports.saveCustomer = exports.updateCustomer = exports.saveLeadNotes = exports.updateLeadDisposition = exports.activateOpportunities = exports.reassignOpportunities = exports.fetchLeadsByCustomer = exports.transformOpportunitiesResponse = exports.fetchOpportunities = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _lodash = _interopRequireDefault(require("lodash"));

var _moment = _interopRequireDefault(require("moment"));

var _uuid = require("uuid");

var _2 = require("../../");

var opportunityMock = _interopRequireWildcard(require("./OpportunityMock"));

var _utils = require("../../utils");

var _UserActions = require("../../user/reducer/UserActions");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var call;

var once = function once() {
  var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  if (call) {
    call.cancel('Only one request allowed at a time.');
  }

  call = _axios["default"].CancelToken.source();
  config.cancelToken = call.token;
  return (0, _axios["default"])(config);
};

var fetchOpportunities = function fetchOpportunities(params) {
  if (_2.isMock) {
    return opportunityMock.fetchOpportunities(params);
  }

  return once({
    method: 'post',
    url: "".concat(_2.serviceContext, "/lead/list/menuLink"),
    data: params
  }).then(function (_ref) {
    var data = _ref.data;
    // return JSON.parse(data);
    return data;
  }).then(function (response) {
    if (response.ok === true) {
      var data = transformOpportunitiesResponse(response.data);
      response.data = data;
    }

    return response;
  });
};

exports.fetchOpportunities = fetchOpportunities;

var transformOpportunitiesResponse = function transformOpportunitiesResponse() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

  var transformedData = _lodash["default"].map(data, function (o) {
    var newObj = {
      dobGrid: o.dob ? (0, _moment["default"])(o.dob, _utils.AC_CONSTANTS.AC_DATE_FORMAT_SERVICE).format(_utils.AC_CONSTANTS.AC_DATE_FORMAT) : null,
      activityDueDateGrid: o.activityDueDate ? (0, _moment["default"])(o.activityDueDate, _utils.AC_CONSTANTS.AC_DATE_FORMAT_SERVICE).format(_utils.AC_CONSTANTS.AC_DATE_FORMAT) : null,
      followUpDateGrid: o.followUpDate ? (0, _moment["default"])(o.followUpDate, _utils.AC_CONSTANTS.AC_DATE_FORMAT_SERVICE).format(_utils.AC_CONSTANTS.AC_DATE_FORMAT) : null,
      followUpDateTimeGrid: o.followUpDate ? (0, _moment["default"])(o.followUpDate, _utils.AC_CONSTANTS.AC_DATE_FORMAT_SERVICE).format(_utils.AC_CONSTANTS.AC_DATE_TIME_FORMAT) : null,
      lastDispositionGrid: o.lastDispositionDate ? (0, _moment["default"])(o.lastDispositionDate, _utils.AC_CONSTANTS.AC_DATE_FORMAT_SERVICE).format(_utils.AC_CONSTANTS.AC_DATE_FORMAT) : null,
      leadCreatedDateTimeGrid: o.leadCreatedDateTime ? (0, _moment["default"])(o.leadCreatedDateTime, _utils.AC_CONSTANTS.AC_DATE_FORMAT_SERVICE).format(_utils.AC_CONSTANTS.AC_DATE_FORMAT) : null,
      leadAssignedDateGrid: o.leadAssignedDate ? (0, _moment["default"])(o.leadAssignedDate, _utils.AC_CONSTANTS.AC_DATE_FORMAT_SERVICE).format(_utils.AC_CONSTANTS.AC_DATE_FORMAT) : null
    };
    return _objectSpread(_objectSpread({}, o), newObj);
  });

  return transformedData;
};

exports.transformOpportunitiesResponse = transformOpportunitiesResponse;

var fetchLeadsByCustomer = function fetchLeadsByCustomer(params) {
  if (_2.isMock) {
    return opportunityMock.fetchOpportunities(params);
  }

  return (0, _axios["default"])({
    method: 'get',
    url: "".concat(_2.serviceContext, "/lead/leadView/customer/").concat(params.customerId),
    data: {}
  }).then(function (_ref2) {
    var data = _ref2.data;
    // return JSON.parse(data);
    return data;
  }).then(function (response) {
    if (response.ok === true) {
      var data = transformOpportunitiesResponse(response.data);
      response.data = data;
    }

    return response;
  });
};

exports.fetchLeadsByCustomer = fetchLeadsByCustomer;

var reassignOpportunities = function reassignOpportunities(params) {
  if (_2.isMock) {
    return opportunityMock.reassignOpportunities(params);
  }

  return (0, _axios["default"])({
    method: 'post',
    url: "".concat(_2.serviceContext, "/lead/assign"),
    data: params
  }).then(function (_ref3) {
    var data = _ref3.data;
    // return JSON.parse(data);
    return data;
  });
};

exports.reassignOpportunities = reassignOpportunities;

var activateOpportunities = function activateOpportunities(params) {
  if (_2.isMock) {
    return opportunityMock.reassignOpportunities(params);
  }

  return (0, _axios["default"])({
    method: 'post',
    url: "".concat(_2.serviceContext, "/lead/activate"),
    data: params
  }).then(function (_ref4) {
    var data = _ref4.data;
    // return JSON.parse(data);
    return data;
  });
};

exports.activateOpportunities = activateOpportunities;

var updateLeadDisposition = function updateLeadDisposition(params) {
  if (_2.isMock) {
    return opportunityMock.updateLeadDisposition(params);
  }

  return (0, _axios["default"])({
    method: 'post',
    url: "".concat(_2.serviceContext, "/lead/disposition"),
    data: params
  }).then(function (_ref5) {
    var data = _ref5.data;
    // return JSON.parse(data);
    return data;
  });
};

exports.updateLeadDisposition = updateLeadDisposition;

var saveLeadNotes = function saveLeadNotes(params) {
  if (_2.isMock) {
    return opportunityMock.updateLeadDisposition(params);
  }

  return (0, _axios["default"])({
    method: 'post',
    url: "".concat(_2.serviceContext, "/notes/"),
    data: params
  }).then(function (_ref6) {
    var data = _ref6.data;
    // return JSON.parse(data);
    return data;
  });
};

exports.saveLeadNotes = saveLeadNotes;

var updateCustomer = function updateCustomer(params) {
  // const userData = useUser();
  // const currentUser = userData.data[0] || {};
  // console.log('updateCustomer ->', currentUser);
  if (_2.isMock) {
    return opportunityMock.updateCustomer(params);
  }

  return (0, _axios["default"])({
    method: 'post',
    url: "".concat(_2.serviceContext, "/lead/customer"),
    data: params
  }).then(function (_ref7) {
    var data = _ref7.data;
    // return JSON.parse(data);
    return data;
  });
};

exports.updateCustomer = updateCustomer;

var saveCustomer = function saveCustomer(params) {
  // const userData = useUser();
  // const currentUser = userData.data[0] || {};
  // console.log('updateCustomer ->', currentUser);
  if (_2.isMock) {
    return opportunityMock.updateCustomer(params);
  }

  return (0, _axios["default"])({
    method: 'post',
    url: "".concat(_2.serviceContext, "/customer"),
    data: params
  }).then(function (_ref8) {
    var data = _ref8.data;
    // return JSON.parse(data);
    return data;
  });
};

exports.saveCustomer = saveCustomer;

var saveLead = function saveLead(params) {
  // const userData = useUser();
  // const currentUser = userData.data[0] || {};
  // console.log('updateCustomer ->', currentUser);
  if (_2.isMock) {
    return opportunityMock.updateCustomer(params);
  }

  return (0, _axios["default"])({
    method: 'post',
    url: "".concat(_2.serviceContext, "/lead"),
    data: params
  }).then(function (_ref9) {
    var data = _ref9.data;
    // return JSON.parse(data);
    return data;
  });
};

exports.saveLead = saveLead;

var fetchLeadById = function fetchLeadById(params) {
  if (_2.isMock) {
    return opportunityMock.fetchOpportunities(params);
  }

  return (0, _axios["default"])({
    method: 'get',
    url: "".concat(_2.serviceContext, "/lead/leadView/").concat(params.leadId),
    data: {},
    params: {
      filterFor: 'display'
    }
  }).then(function (_ref10) {
    var data = _ref10.data;
    // return JSON.parse(data);
    return data;
  }).then(function (response) {
    if (response.ok === true) {
      var data = transformOpportunitiesResponse(response.data);
      response.data = data;
    }

    return response;
  });
};

exports.fetchLeadById = fetchLeadById;

var fetchLeadByInbound = function fetchLeadByInbound(params) {
  if (_2.isMock) {
    return opportunityMock.fetchOpportunities(params);
  }

  return (0, _axios["default"])({
    method: 'post',
    url: "".concat(_2.serviceContext, "/dialler/getLeadView"),
    data: params
  }).then(function (_ref11) {
    var data = _ref11.data;
    // return JSON.parse(data);
    return data;
  }).then(function (response) {
    if (response.ok === true) {
      var data = transformOpportunitiesResponse(response.data);
      response.data = data;
    }

    return response;
  });
};

exports.fetchLeadByInbound = fetchLeadByInbound;

var resetConsentToContact = function resetConsentToContact(params) {
  if (_2.isMock) {
    return opportunityMock.updateCustomer(params);
  }

  return (0, _axios["default"])({
    method: 'post',
    url: "".concat(_2.serviceContext, "/lead/consentToContact"),
    data: params
  }).then(function (_ref12) {
    var data = _ref12.data;
    // return JSON.parse(data);
    return data;
  });
};

exports.resetConsentToContact = resetConsentToContact;