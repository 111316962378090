"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setVisibility = exports.useVisibility = exports.setDept = exports.useDept = exports.setDisttype = exports.useDisttype = exports.setEmptype = exports.useEmptype = exports.setPriority = exports.usePriority = exports.setSubtype = exports.useSubtype = exports.setType = exports.useType = exports.setStatus = exports.useStatus = exports.setLookUp = exports.useLookUp = void 0;

var _hooksForRedux = require("../../provider/hooks-for-redux");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var _useRedux = (0, _hooksForRedux.useRedux)('lookup', {
  lookupLoading: false,
  error: false,
  errorMsg: '',
  rowData: [],
  selectedLookup: null
}, {
  setLookUp: function setLookUp(oldState, newState) {
    return _objectSpread(_objectSpread({}, oldState), newState);
  }
}),
    _useRedux2 = _slicedToArray(_useRedux, 2),
    useLookUp = _useRedux2[0],
    setLookUp = _useRedux2[1].setLookUp;

exports.setLookUp = setLookUp;
exports.useLookUp = useLookUp;

var _useRedux3 = (0, _hooksForRedux.useRedux)('status', {
  statusLoading: false,
  error: false,
  errorMsg: '',
  data: []
}, {
  setStatus: function setStatus(oldState, newState) {
    return _objectSpread(_objectSpread({}, oldState), newState);
  }
}),
    _useRedux4 = _slicedToArray(_useRedux3, 2),
    useStatus = _useRedux4[0],
    setStatus = _useRedux4[1].setStatus;

exports.setStatus = setStatus;
exports.useStatus = useStatus;

var _useRedux5 = (0, _hooksForRedux.useRedux)('type', {
  typeLoading: false,
  error: false,
  errorMsg: '',
  data: []
}, {
  setType: function setType(oldState, newState) {
    return _objectSpread(_objectSpread({}, oldState), newState);
  }
}),
    _useRedux6 = _slicedToArray(_useRedux5, 2),
    useType = _useRedux6[0],
    setType = _useRedux6[1].setType;

exports.setType = setType;
exports.useType = useType;

var _useRedux7 = (0, _hooksForRedux.useRedux)('subtype', {
  subtypeLoading: false,
  error: false,
  errorMsg: '',
  data: []
}, {
  setSubtype: function setSubtype(oldState, newState) {
    return _objectSpread(_objectSpread({}, oldState), newState);
  }
}),
    _useRedux8 = _slicedToArray(_useRedux7, 2),
    useSubtype = _useRedux8[0],
    setSubtype = _useRedux8[1].setSubtype;

exports.setSubtype = setSubtype;
exports.useSubtype = useSubtype;

var _useRedux9 = (0, _hooksForRedux.useRedux)('priority', {
  priorityLoading: false,
  error: false,
  errorMsg: '',
  data: []
}, {
  setPriority: function setPriority(oldState, newState) {
    return _objectSpread(_objectSpread({}, oldState), newState);
  }
}),
    _useRedux10 = _slicedToArray(_useRedux9, 2),
    usePriority = _useRedux10[0],
    setPriority = _useRedux10[1].setPriority;

exports.setPriority = setPriority;
exports.usePriority = usePriority;

var _useRedux11 = (0, _hooksForRedux.useRedux)('emptype', {
  emptypeLoading: false,
  error: false,
  errorMsg: '',
  data: []
}, {
  setEmptype: function setEmptype(oldState, newState) {
    return _objectSpread(_objectSpread({}, oldState), newState);
  }
}),
    _useRedux12 = _slicedToArray(_useRedux11, 2),
    useEmptype = _useRedux12[0],
    setEmptype = _useRedux12[1].setEmptype;

exports.setEmptype = setEmptype;
exports.useEmptype = useEmptype;

var _useRedux13 = (0, _hooksForRedux.useRedux)('disttype', {
  disttypeLoading: false,
  error: false,
  errorMsg: '',
  data: []
}, {
  setDisttype: function setDisttype(oldState, newState) {
    return _objectSpread(_objectSpread({}, oldState), newState);
  }
}),
    _useRedux14 = _slicedToArray(_useRedux13, 2),
    useDisttype = _useRedux14[0],
    setDisttype = _useRedux14[1].setDisttype;

exports.setDisttype = setDisttype;
exports.useDisttype = useDisttype;

var _useRedux15 = (0, _hooksForRedux.useRedux)('department', {
  departmentLoading: false,
  error: false,
  errorMsg: '',
  data: []
}, {
  setDept: function setDept(oldState, newState) {
    return _objectSpread(_objectSpread({}, oldState), newState);
  }
}),
    _useRedux16 = _slicedToArray(_useRedux15, 2),
    useDept = _useRedux16[0],
    setDept = _useRedux16[1].setDept;

exports.setDept = setDept;
exports.useDept = useDept;

var _useRedux17 = (0, _hooksForRedux.useRedux)('visibility', {
  visibilityLoading: false,
  error: false,
  errorMsg: '',
  data: []
}, {
  setVisibility: function setVisibility(oldState, newState) {
    return _objectSpread(_objectSpread({}, oldState), newState);
  }
}),
    _useRedux18 = _slicedToArray(_useRedux17, 2),
    useVisibility = _useRedux18[0],
    setVisibility = _useRedux18[1].setVisibility;

exports.setVisibility = setVisibility;
exports.useVisibility = useVisibility;