"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ONLY_ALPHA_NUMERIC_WITH_SPACE_HYPEN = exports.ONLY_ALPHA_NUMERIC = exports.ONLY_ALPHABETS = exports.ONLY_DECIMAL_NUMBER = exports.ONLY_NUMBER = void 0;

var _utils = require("../utils");

var ONLY_NUMBER = function ONLY_NUMBER(newValue, oldValue) {
  if (newValue === '' || _utils.AC_CONSTANTS.AC_ONLY_NUMBER.test(newValue)) {
    return newValue;
  }

  return oldValue;
};

exports.ONLY_NUMBER = ONLY_NUMBER;

var ONLY_DECIMAL_NUMBER = function ONLY_DECIMAL_NUMBER(newValue, oldValue) {
  if (newValue === '' || _utils.AC_CONSTANTS.AC_ONLY_DECIMAL_NUMBER.test(newValue)) {
    return newValue;
  }

  return oldValue;
};

exports.ONLY_DECIMAL_NUMBER = ONLY_DECIMAL_NUMBER;

var ONLY_ALPHABETS = function ONLY_ALPHABETS(newValue, oldValue) {
  if (newValue === '' || _utils.AC_CONSTANTS.AC_ONLY_ALPHABETS.test(newValue)) {
    return newValue;
  }

  return oldValue;
};

exports.ONLY_ALPHABETS = ONLY_ALPHABETS;

var ONLY_ALPHA_NUMERIC = function ONLY_ALPHA_NUMERIC(newValue, oldValue) {
  if (newValue === '' || _utils.AC_CONSTANTS.AC_ONLY_ALPHA_NUMERIC.test(newValue)) {
    return newValue;
  }

  return oldValue;
};

exports.ONLY_ALPHA_NUMERIC = ONLY_ALPHA_NUMERIC;

var ONLY_ALPHA_NUMERIC_WITH_SPACE_HYPEN = function ONLY_ALPHA_NUMERIC_WITH_SPACE_HYPEN(newValue, oldValue) {
  if (newValue === '' || _utils.AC_CONSTANTS.AC_ONLY_ALPHA_NUMERIC_WITH_SPACE_HYPEN.test(newValue)) {
    return newValue;
  }

  return oldValue;
};

exports.ONLY_ALPHA_NUMERIC_WITH_SPACE_HYPEN = ONLY_ALPHA_NUMERIC_WITH_SPACE_HYPEN;