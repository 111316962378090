"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ColumnDefs = void 0;

var _datagrid = require("../datagrid");

var ColumnDefs = [{
  field: 'activityType',
  headerName: 'Activity Type'
}, {
  field: 'activitySubtype',
  headerName: 'Activity Sub-Type'
}, {
  field: 'taskName',
  headerName: 'Task Name'
}, {
  field: 'status',
  headerName: 'Status',
  cellRenderer: 'statusRenderer'
}];
exports.ColumnDefs = ColumnDefs;