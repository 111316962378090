"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "MyActionedPage", {
  enumerable: true,
  get: function get() {
    return _MyActionedPage.MyActionedPage;
  }
});

var _MyActionedPage = require("./MyActionedPage");