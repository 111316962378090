"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ColumnDefs = void 0;

var _datagrid = require("../datagrid");

var ColumnDefs = [// {
//   field: 'contactId',
//   headerName: 'Contact Id'
// },
{
  field: 'firstName',
  headerName: 'First Name'
}, {
  field: 'lastName',
  headerName: 'Last Name'
}, {
  field: 'accountId',
  headerName: 'Account'
}, {
  field: 'address1',
  headerName: 'Address'
}, {
  field: 'city',
  headerName: 'City'
}, {
  field: 'state',
  headerName: 'State'
}, {
  field: 'zip',
  headerName: 'Zip'
}, {
  field: 'phone1',
  headerName: 'Primary Phone'
}, {
  field: 'email1',
  headerName: 'Email'
}];
exports.ColumnDefs = ColumnDefs;