"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ColumnDefs = void 0;

var _datagrid = require("../datagrid");

var ColumnDefs = [{
  field: 'activityType',
  headerName: 'Type'
}, {
  field: 'activitySubtype',
  headerName: 'Subtype'
}, {
  field: 'assignedToName',
  headerName: 'Assigned To'
}, {
  field: 'activityStatus',
  headerName: 'Status',
  cellRenderer: 'statusRenderer'
}, {
  field: 'dueDate',
  headerName: 'Due Date'
}, {
  field: 'priority',
  headerName: 'Proirity'
}, {
  field: 'activitySubstatus',
  headerName: 'Sub-Status'
}, // {
//   field: 'modifiedDate',
//   headerName: 'Status Changed Date'
// },
{
  field: 'team',
  headerName: 'Team'
}, {
  field: 'accountName',
  headerName: 'Account'
}, {
  field: 'contactName',
  headerName: 'Contact'
}, {
  field: 'assignedByName',
  headerName: 'Assigned By'
}, {
  field: 'assignedByDate',
  headerName: 'Assigned By Date'
} // {
//   field: 'activityId',
//   headerName: 'ActivityId'
// }
];
exports.ColumnDefs = ColumnDefs;