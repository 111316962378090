"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.triggerStageLead = exports.uploadLeadsFile = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _ = require("../../");

var serviceMock = _interopRequireWildcard(require("./StagingMock"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

// import _ from 'lodash';
var uploadLeadsFile = function uploadLeadsFile(_ref) {
  var formData = _ref.formData,
      queryParams = _ref.queryParams,
      onUploadProgress = _ref.onUploadProgress;

  if (_.isMock) {
    return serviceMock.uploadLeadsFileMock({});
  }

  return (0, _axios["default"])({
    method: 'post',
    url: "".concat(_.serviceContextCloudBatch, "/file/inLeadsFile"),
    data: formData,
    params: queryParams,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: onUploadProgress
  }).then(function (_ref2) {
    var data = _ref2.data;
    // return JSON.parse(data);
    return data;
  });
};

exports.uploadLeadsFile = uploadLeadsFile;

var triggerStageLead = function triggerStageLead(params) {
  if (_.isMock) {
    return serviceMock.triggerStageLeadMock(params);
  }

  return (0, _axios["default"])({
    method: 'post',
    url: "".concat(_.serviceContextCloudBatch, "/batch/convertStageLeads"),
    data: params
  }).then(function (_ref3) {
    var data = _ref3.data;
    // return JSON.parse(data);
    return data;
  });
};

exports.triggerStageLead = triggerStageLead;