"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LANGUAGES = void 0;
var LANGUAGES = [{
  id: 1,
  label: 'English'
}, {
  id: 2,
  label: 'Spanish'
}];
exports.LANGUAGES = LANGUAGES;