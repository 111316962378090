"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createOrUpdateContact = exports.fetchContactByFname = exports.fetchContact = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _ContactAction = require("./ContactAction");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var fetchContact = function fetchContact(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [{}];
      resolve({
        ok: true,
        data: data
      });
    }, 500);
  });
};

exports.fetchContact = fetchContact;

var fetchContactByFname = function fetchContactByFname(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [{}];
      resolve({
        ok: true,
        data: data
      });
    }, 500);
  });
};

exports.fetchContactByFname = fetchContactByFname;

var createOrUpdateContact = function createOrUpdateContact(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [_objectSpread({
        messageId: 19,
        createdBy: 'user1',
        isActiveStatus: 'Y',
        message: 'broadcast message eight',
        version: 0,
        modifiedBy: 'user1',
        messagePriority: 'M',
        createdDate: '2020-08-08 07:40:23',
        modifiedDate: '2020-08-08 07:40:23',
        messageStartDate: '2020-08-08 06:26:48',
        messageEndDate: '2020-08-11 11:26:48'
      }, params)];
      resolve({
        ok: true,
        data: data
      });
    }, 500);
  });
};

exports.createOrUpdateContact = createOrUpdateContact;