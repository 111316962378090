"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchDispositionMaster = exports.fetchAgentsMaster = exports.fetchCampaignsMaster = exports.fetchAssignedGroupMaster = exports.fetchSourceMaster = exports.fetchAddressViaZip = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _lodash = _interopRequireDefault(require("lodash"));

var _serviceContext = require("../../provider/serviceContext");

var MockService = _interopRequireWildcard(require("./UtilMock"));

var _SourceActions = require("../reducer/SourceActions");

var _AssignedGroupActions = require("../reducer/AssignedGroupActions");

var _2 = require("..");

var _CampaignsActions = require("../reducer/CampaignsActions");

var _userRoles = require("../../user-roles");

var _AgentActions = require("../reducer/AgentActions");

var _DispositionActions = require("../reducer/DispositionActions");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var fetchAddressViaZip = function fetchAddressViaZip(params) {
  if (_serviceContext.isMock) {
    return MockService.fetchAddressViaZip(params);
  }

  return (0, _axios["default"])({
    method: 'get',
    url: "".concat(_serviceContext.serviceContext, "/refData/zipCode/").concat(params.zip),
    data: {}
  }).then(function (_ref) {
    var data = _ref.data;
    // return JSON.parse(data);
    return data;
  });
};

exports.fetchAddressViaZip = fetchAddressViaZip;

var fetchSourceMaster = function fetchSourceMaster(params) {
  if (_serviceContext.isMock) {
    return MockService.fetchSourceMaster(params);
  }

  return (0, _axios["default"])({
    method: 'get',
    url: "".concat(_serviceContext.serviceContext, "/source"),
    //?filterFor=display
    data: {},
    params: {
      filterFor: 'display'
    }
  }).then(function (_ref2) {
    var data = _ref2.data;
    // return JSON.parse(data);
    return data;
  }).then(function (response) {
    if (response.ok === true) {
      var responseData = response.data ? response.data : [];

      var sortedData = _lodash["default"].sortBy(responseData, ['sourceLevel1']);

      (0, _SourceActions.setSourceMaster)({
        sourceLoading: false,
        error: false,
        errorMsg: '',
        data: sortedData
      });
    } else if (response.ok === false) {
      (0, _SourceActions.setSourceMaster)({
        sourceLoading: false,
        error: true,
        errorMsg: 'Source Service Failed',
        data: []
      });
    }
  })["catch"](function (err) {
    console.error(err);
    (0, _SourceActions.setSourceMaster)({
      sourceLoading: false,
      error: true,
      errorMsg: 'Source Service Failed',
      data: []
    });
  });
};

exports.fetchSourceMaster = fetchSourceMaster;

var fetchAssignedGroupMaster = function fetchAssignedGroupMaster(params) {
  if (_serviceContext.isMock) {
    return MockService.fetchAssignedGroupMaster(params);
  }

  return (0, _axios["default"])({
    method: 'get',
    url: "".concat(_serviceContext.serviceContext, "/assignedGroup"),
    //?filterFor=display
    data: {},
    params: {
      filterFor: 'display'
    }
  }).then(function (_ref3) {
    var data = _ref3.data;
    // return JSON.parse(data);
    return data;
  }).then(function (response) {
    if (response.ok === true) {
      var responseData = response.data ? response.data : [];

      var sortedData = _lodash["default"].sortBy(responseData, ['assignedGroup']);

      (0, _AssignedGroupActions.setAssignedGroupMaster)({
        assignedGroupLoading: false,
        error: false,
        errorMsg: '',
        data: sortedData
      });
    } else if (response.ok === false) {
      (0, _AssignedGroupActions.setAssignedGroupMaster)({
        assignedGroupLoading: false,
        error: true,
        errorMsg: 'Assigned Group Service Failed',
        data: []
      });
    }
  })["catch"](function (err) {
    console.error(err);
    (0, _AssignedGroupActions.setAssignedGroupMaster)({
      assignedGroupLoading: false,
      error: true,
      errorMsg: 'Assigned Group Service Failed',
      data: []
    });
  });
};

exports.fetchAssignedGroupMaster = fetchAssignedGroupMaster;

var fetchCampaignsMaster = function fetchCampaignsMaster(params) {
  if (_serviceContext.isMock) {
    return MockService.fetchCampaignsMaster(params);
  }

  return (0, _axios["default"])({
    method: 'get',
    url: "".concat(_serviceContext.serviceContext, "/campaigns/carrier/").concat(params.carrierId, "/client/").concat(_2.AC_CONSTANTS.HARDCODING_clientId),
    data: {},
    params: {
      filterFor: 'display'
    }
  }).then(function (_ref4) {
    var data = _ref4.data;
    // return JSON.parse(data);
    return data;
  }).then(function (response) {
    if (response.ok === true) {
      var responseData = response.data ? response.data : [];

      var sortedData = _lodash["default"].sortBy(responseData, ['campaignName']);

      (0, _CampaignsActions.setCampaignsMaster)({
        campaignsLoading: false,
        error: false,
        errorMsg: '',
        data: sortedData
      });
    } else if (response.ok === false) {
      (0, _CampaignsActions.setCampaignsMaster)({
        campaignsLoading: false,
        error: true,
        errorMsg: 'Campaigns Service Failed',
        data: []
      });
    }
  })["catch"](function (err) {
    console.error(err);
    (0, _CampaignsActions.setCampaignsMaster)({
      campaignsLoading: false,
      error: true,
      errorMsg: 'Campaigns Service Failed',
      data: []
    });
  });
};

exports.fetchCampaignsMaster = fetchCampaignsMaster;

var fetchAgentsMaster = function fetchAgentsMaster() {
  var params = {
    role: _userRoles.ACRoles.Agent
  };

  if (_serviceContext.isMock) {
    return MockService.fetchAgentsMaster(params);
  }

  return (0, _axios["default"])({
    method: 'get',
    url: "".concat(_serviceContext.serviceContext, "/userRole/role/").concat(params.role),
    data: {},
    params: {
      filterFor: 'display'
    }
  }).then(function (_ref5) {
    var data = _ref5.data;
    // return JSON.parse(data);
    return data;
  }).then(function (response) {
    if (response.ok === true) {
      (0, _AgentActions.setAgentsMaster)({
        agentsLoading: false,
        error: false,
        errorMsg: '',
        data: _toConsumableArray(response.data)
      });
    } else if (response.ok === false) {
      (0, _AgentActions.setAgentsMaster)({
        agentsLoading: false,
        error: true,
        errorMsg: 'Agents Service Failed',
        data: []
      });
    }
  })["catch"](function (err) {
    console.error(err);
    (0, _AgentActions.setAgentsMaster)({
      agentsLoading: false,
      error: true,
      errorMsg: 'Agents Service Failed',
      data: []
    });
  });
};

exports.fetchAgentsMaster = fetchAgentsMaster;

var fetchDispositionMaster = function fetchDispositionMaster(params) {
  if (_serviceContext.isMock) {
    return MockService.fetchDispositionMaster(params);
  }

  return (0, _axios["default"])({
    method: 'get',
    url: "".concat(_serviceContext.serviceContext, "/despositions"),
    data: {},
    params: {
      filterFor: 'display'
    }
  }).then(function (_ref6) {
    var data = _ref6.data;
    // return JSON.parse(data);
    return data;
  }).then(function (response) {
    if (response.ok === true) {
      var responseData = response.data ? response.data : [];

      var sortedData = _lodash["default"].sortBy(responseData, ['dispositionName']);

      (0, _DispositionActions.setDispositionMaster)({
        dispositionLoading: false,
        error: false,
        errorMsg: '',
        data: sortedData
      });
    } else if (response.ok === false) {
      (0, _DispositionActions.setDispositionMaster)({
        dispositionLoading: false,
        error: true,
        errorMsg: 'Disposition Service Failed',
        data: []
      });
    }
  })["catch"](function (err) {
    console.error(err);
    (0, _DispositionActions.setDispositionMaster)({
      dispositionLoading: false,
      error: true,
      errorMsg: 'Disposition Service Failed',
      data: []
    });
  });
};

exports.fetchDispositionMaster = fetchDispositionMaster;