"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ACTIVITY_STATUS = void 0;
var ACTIVITY_STATUS = {
  NO_DOCUMENTS_REQUIRED: 'No Documents Required',
  DOCUMENTS_NEEDED: 'Documents Needed',
  UPLOADED_TO_MARKETPLACE: 'Uploaded to Marketplace',
  RESOLVED_TO_MARKETPLACE: 'Resolved in Marketplace'
};
exports.ACTIVITY_STATUS = ACTIVITY_STATUS;