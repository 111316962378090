"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateCustomer = exports.updateLeadDisposition = exports.reassignOpportunities = exports.fetchOpportunities = void 0;

var _basic = require("../../basic");

var _lodash = _interopRequireDefault(require("lodash"));

var _moment = _interopRequireDefault(require("moment"));

var _uuid = require("uuid");

var _utils = require("../../utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var PAGE_SIZE = 500;
var campaign = ['pmedjun01', 'pmedjmay15', 'pmedjun01', 'pmedjun15'];
var fn = ['Danielle', 'Andy', 'Parker', 'Mark', 'Jack'];
var ln = ['McNally', 'Mendoza', 'Priola', 'Perry', 'Evans'];
var status = ['active', 'callback'];
var disposition = ['sale', 'callback'];
var current = ['M', 'H', 'D'];

function randomDate(start, end) {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

var fetchOpportunities = function fetchOpportunities() {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [];

      _lodash["default"].times(PAGE_SIZE, function (i) {
        var firstName = fn[(0, _basic.getRandomInt)(0, fn.length - 1)];
        var lastName = ln[(0, _basic.getRandomInt)(0, ln.length - 1)];
        var email = firstName.toLowerCase() + '.' + lastName.toLowerCase() + '@gmail.com';
        data.push({
          id: (0, _uuid.v4)(),
          leadId: (0, _uuid.v4)(),
          agentId: 'Agent-ID-' + (0, _basic.getRandomInt)(100, 105),
          source: 'Third party',
          campaign: campaign[(0, _basic.getRandomInt)(0, campaign.length - 1)],
          firstName: firstName,
          lastName: lastName,
          dob: (0, _moment["default"])(randomDate(new Date(1970, 0, 1), new Date(2000, 0, 1))).format(_utils.AC_CONSTANTS.AC_DATE_FORMAT),
          contact: "(".concat((0, _basic.getRandomInt)(100, 999), ") ").concat((0, _basic.getRandomInt)(100, 999), "-").concat((0, _basic.getRandomInt)(1000, 9999)),
          email: email,
          status: status[(0, _basic.getRandomInt)(0, status.length - 1)],
          lastcontact: (0, _moment["default"])(randomDate(new Date(2019, 0, 1), new Date())).format(_utils.AC_CONSTANTS.AC_DATE_FORMAT),
          disposition: disposition[(0, _basic.getRandomInt)(0, disposition.length - 1)],
          current: current[(0, _basic.getRandomInt)(0, current.length - 1)],
          dt_added: (0, _moment["default"])(randomDate(new Date(2018, 8, 1), new Date(2018, 9, 1))).format(_utils.AC_CONSTANTS.AC_DATE_FORMAT),
          dt_assigned: (0, _moment["default"])(randomDate(new Date(2019, 0, 1), new Date())).format(_utils.AC_CONSTANTS.AC_DATE_FORMAT)
        });
      });

      resolve({
        ok: true,
        data: data
      });
    }, 500);
  });
};

exports.fetchOpportunities = fetchOpportunities;

var reassignOpportunities = function reassignOpportunities(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [];
      resolve({
        ok: true,
        data: data
      });
    }, 500);
  });
};

exports.reassignOpportunities = reassignOpportunities;

var updateLeadDisposition = function updateLeadDisposition(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [];
      resolve({
        ok: true,
        data: data
      });
    }, 500);
  });
};

exports.updateLeadDisposition = updateLeadDisposition;

var updateCustomer = function updateCustomer(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [];
      resolve({
        ok: true,
        data: data
      });
    }, 500);
  });
};

exports.updateCustomer = updateCustomer;