"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchDispositionMaster = exports.fetchAgentsMaster = exports.fetchCampaignsMaster = exports.fetchAssignedGroupMaster = exports.fetchSourceMaster = exports.fetchAddressViaZip = void 0;

var _SourceActions = require("../reducer/SourceActions");

var _AssignedGroupActions = require("../reducer/AssignedGroupActions");

var _CampaignsActions = require("../reducer/CampaignsActions");

var _AgentActions = require("../reducer/AgentActions");

var _DispositionActions = require("../reducer/DispositionActions");

// import _ from 'lodash';
// import moment from 'moment';
// import { v4 } from 'uuid';
var fetchAddressViaZip = function fetchAddressViaZip(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [{
        zipCode: '00501',
        type: 'UNIQUE',
        decommissioned: 0,
        primaryCity: 'Holtsville',
        acceptableCities: '',
        unacceptableCities: null,
        state: 'NY',
        county: 'Suffolk County',
        timeZone: null,
        areaCode: '631',
        worldRegion: 'NA',
        country: 'US',
        latitude: '0.0',
        longitude: 0.0
      }];
      resolve({
        ok: true,
        data: data
      });
    }, 100);
  });
};

exports.fetchAddressViaZip = fetchAddressViaZip;

var fetchSourceMaster = function fetchSourceMaster(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [{
        sourceId: 1,
        createdBy: 'Phani',
        modifiedBy: null,
        createdDateTime: '2020-08-06 01:05:31',
        modifiedDateTime: null,
        sourceLevel1: 'BCBS-',
        sourceLevel2: 'NOVIK'
      }, {
        sourceId: 2,
        createdBy: 'Phani',
        modifiedBy: '145',
        createdDateTime: '2020-08-06 01:05:31',
        modifiedDateTime: '2020-08-14 07:48:31',
        sourceLevel1: 'WEBSITE1',
        sourceLevel2: 'APPLE1'
      }];
      resolve({
        ok: true,
        data: data
      });
      (0, _SourceActions.setSourceMaster)({
        sourceLoading: false,
        error: false,
        errorMsg: '',
        data: [].concat(data)
      });
    }, 100);
  });
};

exports.fetchSourceMaster = fetchSourceMaster;

var fetchAssignedGroupMaster = function fetchAssignedGroupMaster(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [{
        assignedGroupId: 1,
        assignedGroup: 'Memorial',
        createdBy: 'nishtha',
        createdDateTime: '2020-08-11 18:16:11',
        modifiedBy: 'nishtha',
        modifiedDateTime: '2020-08-11 18:16:11',
        isActiveStatus: 'Y'
      }, {
        assignedGroupId: 4,
        assignedGroup: 'memorial',
        createdBy: 'nishtha',
        createdDateTime: '2020-08-11 19:32:47',
        modifiedBy: 'nishtha',
        modifiedDateTime: '2020-08-11 19:32:47',
        isActiveStatus: 'Y'
      }, {
        assignedGroupId: 5,
        assignedGroup: 'Chen',
        createdBy: '678',
        createdDateTime: '2020-08-14 10:10:04',
        modifiedBy: '678',
        modifiedDateTime: '2020-08-14 10:10:04',
        isActiveStatus: 'Y'
      }];
      resolve({
        ok: true,
        data: data
      });
      (0, _AssignedGroupActions.setAssignedGroupMaster)({
        assignedGroupLoading: false,
        error: false,
        errorMsg: '',
        data: [].concat(data)
      });
    }, 100);
  });
};

exports.fetchAssignedGroupMaster = fetchAssignedGroupMaster;

var fetchCampaignsMaster = function fetchCampaignsMaster(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [{
        campaignId: 1,
        campaignType: null,
        campaignName: 'Medical-One',
        description: 'First Medical Campaign',
        carrierId: 1,
        clientId: 1,
        startDate: '2020-07-01 00:00:00',
        endDate: '2020-11-30 00:00:00',
        status: null,
        productType: 'M',
        createdBy: 'phani',
        createdDateTime: '2020-07-16 02:27:42',
        modifiedBy: null,
        modifiedDateTime: null,
        isActiveStatus: 'Y',
        version: 1
      }];
      resolve({
        ok: true,
        data: data
      });
      (0, _CampaignsActions.setCampaignsMaster)({
        campaignsLoading: false,
        error: false,
        errorMsg: '',
        data: [].concat(data)
      });
    }, 100);
  });
};

exports.fetchCampaignsMaster = fetchCampaignsMaster;

var fetchAgentsMaster = function fetchAgentsMaster(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [{
        managerId: null,
        firstName: 'Shri',
        lastName: 'Admin',
        emailId: 'shrikant@xyz.com',
        dob: '2020-06-30 00:00:00',
        gender: null,
        agentStatus: null,
        userId: 1,
        addressLn1: null,
        addressLn2: null,
        city: null,
        state: null,
        zip: null,
        phone1: '(786)7043983',
        phone2: '1321321321',
        commissionScale: null,
        login: 'Shri Login ID',
        password: null,
        languagesPreferred: null,
        createdDateTime: '2020-07-16 18:18:33',
        modifiedDateTime: '2020-08-13 21:00:38',
        createdBy: '2020-07-16 18:18:33',
        modifiedBy: '0',
        version: 14,
        isActiveStatus: 'Y'
      }, {
        managerId: 1,
        firstName: 'Yogi',
        lastName: 'Chettiar',
        emailId: 'Yogi@xyz.com',
        dob: '2020-07-27 00:00:00',
        gender: null,
        agentStatus: null,
        userId: 2,
        addressLn1: 'South California',
        addressLn2: null,
        city: null,
        state: null,
        zip: null,
        phone1: '(0217) 007',
        phone2: null,
        commissionScale: null,
        login: 'yogi_123',
        password: null,
        languagesPreferred: null,
        createdDateTime: '2020-07-27 12:12:18',
        modifiedDateTime: '2020-07-27 19:01:50',
        createdBy: '0',
        modifiedBy: '0',
        version: 4,
        isActiveStatus: 'Y'
      }, {
        managerId: 2,
        firstName: 'Agent',
        lastName: 'Agent',
        emailId: 'a.b@c',
        dob: '2020-07-16 00:00:00',
        gender: 'Female',
        agentStatus: null,
        userId: 3,
        addressLn1: 'address',
        addressLn2: null,
        city: null,
        state: null,
        zip: null,
        phone1: '1234',
        phone2: null,
        commissionScale: null,
        login: '123',
        password: null,
        languagesPreferred: '1',
        createdDateTime: '2020-07-27 15:40:16',
        modifiedDateTime: '2020-07-28 20:48:38',
        createdBy: '0',
        modifiedBy: '0',
        version: 10,
        isActiveStatus: 'Y'
      }, {
        managerId: 1,
        firstName: 'Analyst',
        lastName: 'Analyst',
        emailId: '',
        dob: '2020-07-07 00:00:00',
        gender: 'Female',
        agentStatus: null,
        userId: 4,
        addressLn1: null,
        addressLn2: null,
        city: null,
        state: null,
        zip: null,
        phone1: '12345',
        phone2: null,
        commissionScale: null,
        login: 'AnalystLogin',
        password: null,
        languagesPreferred: null,
        createdDateTime: '2020-07-27 15:41:33',
        modifiedDateTime: '2020-07-27 21:54:04',
        createdBy: '0',
        modifiedBy: '0',
        version: 7,
        isActiveStatus: 'Y'
      }];
      resolve({
        ok: true,
        data: data
      });
      (0, _AgentActions.setAgentsMaster)({
        agentsLoading: false,
        error: false,
        errorMsg: '',
        data: [].concat(data)
      });
    }, 100);
  });
};

exports.fetchAgentsMaster = fetchAgentsMaster;

var fetchDispositionMaster = function fetchDispositionMaster(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [{
        dispositionId: 1,
        dispositionName: 'Medicare Advantage',
        dispositionNameFb: 'Sold',
        statusId: null,
        status: 'Y',
        version: 1,
        isActiveStatus: 'Y',
        createdBy: 'phani',
        modifiedBy: null,
        modifiedDateTime: null,
        createdDateTime: '2020-09-08 03:20:43'
      }, {
        dispositionId: 2,
        dispositionName: 'QHP-MP',
        dispositionNameFb: 'Sold',
        statusId: null,
        status: 'Y',
        version: 1,
        isActiveStatus: 'Y',
        createdBy: 'phani',
        modifiedBy: null,
        modifiedDateTime: null,
        createdDateTime: '2020-09-08 03:20:43'
      }, {
        dispositionId: 3,
        dispositionName: 'QHP-OFF',
        dispositionNameFb: 'Sold',
        statusId: null,
        status: 'Y',
        version: 1,
        isActiveStatus: 'Y',
        createdBy: 'phani',
        modifiedBy: null,
        modifiedDateTime: null,
        createdDateTime: '2020-09-08 03:20:43'
      }, {
        dispositionId: 4,
        dispositionName: 'Ancillary Only',
        dispositionNameFb: 'Sold',
        statusId: null,
        status: 'Y',
        version: 1,
        isActiveStatus: 'Y',
        createdBy: 'phani',
        modifiedBy: null,
        modifiedDateTime: null,
        createdDateTime: '2020-09-08 03:20:43'
      }, {
        dispositionId: 5,
        dispositionName: 'Renewed 2021',
        dispositionNameFb: 'Sold',
        statusId: null,
        status: 'Y',
        version: 1,
        isActiveStatus: 'Y',
        createdBy: 'phani',
        modifiedBy: null,
        modifiedDateTime: null,
        createdDateTime: '2020-09-08 03:20:43'
      }];
      resolve({
        ok: true,
        data: data
      });
      (0, _DispositionActions.setDispositionMaster)({
        dispositionLoading: false,
        error: false,
        errorMsg: '',
        data: [].concat(data)
      });
    }, 100);
  });
};

exports.fetchDispositionMaster = fetchDispositionMaster;