"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createOrUpdateRoles = exports.transformUsersResponse = exports.fetchRoles = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _lodash = _interopRequireDefault(require("lodash"));

var _moment = _interopRequireDefault(require("moment"));

var _uuid = require("uuid");

var _2 = require("../../");

var rolesMock = _interopRequireWildcard(require("./VisibilityrolesMock"));

var _utils = require("../../utils");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var fetchRoles = function fetchRoles() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    visibilityRoleMenuId: 1
  };

  if (_2.isMock) {
    return rolesMock.fetchRoles(params);
  }

  return (0, _axios["default"])({
    method: 'get',
    url: "".concat(_2.serviceContext, "/visibility"),
    data: params,
    transformResponse: [function (data) {
      return data;
    }]
  }).then(function (_ref) {
    var data = _ref.data;
    return JSON.parse(data); // return data;
  }).then(function (response) {
    if (response.ok === true) {
      var data = transformUsersResponse(response.data);
      response.data = data;
    }

    return response;
  });
};

exports.fetchRoles = fetchRoles;

var transformUsersResponse = function transformUsersResponse() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

  var transformedData = _lodash["default"].map(data, function (o) {
    var newObj = {
      dobGrid: o.dob ? (0, _moment["default"])(o.dob, _utils.AC_CONSTANTS.AC_DATE_FORMAT_SERVICE).format(_utils.AC_CONSTANTS.AC_DATE_FORMAT) : null
    };
    return _objectSpread(_objectSpread({}, o), newObj);
  });

  return transformedData;
};

exports.transformUsersResponse = transformUsersResponse;

var createOrUpdateRoles = function createOrUpdateRoles(params) {
  if (_2.isMock) {
    return rolesMock.createOrUpdateRoles(params);
  }

  return (0, _axios["default"])({
    method: 'post',
    url: "".concat(_2.serviceContext, "/visibility/role/").concat(params.visibilityRole ? params.visibilityRole : ''),
    data: params.menu
  }).then(function (_ref2) {
    var data = _ref2.data;
    // return JSON.parse(data);
    // fetchAllUsersForDropdown(); //TODO: This is temporary for Agents to select various users
    return data;
  });
};

exports.createOrUpdateRoles = createOrUpdateRoles;