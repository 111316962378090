"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.awsInit = awsInit;
exports.getAwsConfigurations = void 0;

var _ = require("./");

var _axios = _interopRequireDefault(require("axios"));

var _awsAmplify = _interopRequireDefault(require("aws-amplify"));

var _AppActions = require("./app/reducer/AppActions");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function awsInit() {
  getAwsConfigurations().then(function (response) {
    if (response && response.data) {
      _awsAmplify["default"].configure({
        Auth: {
          userPoolId: response.data[0].userPoolId,
          userPoolWebClientId: response.data[0].userPoolWebClientId,
          region: response.data[0].region,
          identityPoolId: response.data[0].identityPoolId
        }
      });

      var documentListObj = {
        loading: false
      };
      documentListObj = _objectSpread(_objectSpread({}, documentListObj), {}, {
        data: _toConsumableArray(response.data)
      });
      (0, _AppActions.setAppHeartBeat)(documentListObj);
    }
  })["catch"](function (err) {
    console.error(err.message);
  });
}

var getAwsConfigurations = function getAwsConfigurations() {
  return (0, _axios["default"])({
    method: 'get',
    url: "".concat(_.serviceContext, "/heartBeat/aws"),
    data: {},
    headers: {
      userId: 1,
      'Content-Type': 'application/json'
    }
  }).then(function (_ref) {
    var data = _ref.data;
    // return JSON.parse(data);
    return data;
  });
};

exports.getAwsConfigurations = getAwsConfigurations;