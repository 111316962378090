"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ColumnDefs = void 0;

var _datagrid = require("../../../datagrid");

var ColumnDefs = [// {
//   lockPosition: true,
//   headerCheckboxSelection: true,
//   headerCheckboxSelectionFilteredOnly: true,
//   checkboxSelection: true,
//   minWidth: 55,
//   maxWidth: 55,
//   filter: false
// },
// {
//   field: 'firstName',
//   headerName: 'First Name',
// },
// {
//   field: 'lastName',
//   headerName: 'Last Name'
// },
// {
//   field: 'aor',
//   headerName: 'AOR'
// },
{
  headerName: 'Name',
  valueGetter: function valueGetter(params) {
    return params.data.firstName + ' ' + params.data.lastName;
  }
}, {
  field: 'employeeType',
  headerName: 'Employee Type'
}, {
  field: 'managerName',
  headerName: 'Reports To'
}, {
  field: 'visibilityRole',
  headerName: 'Visibility Role'
}, {
  field: 'visibilityMenu',
  headerName: 'Visibility Menu'
}, // {
//   field: 'emailId',
//   headerName: 'Email'
// },
{
  field: 'login',
  headerName: 'Login Id'
}, {
  field: 'phone1',
  headerName: 'Contact'
}, {
  field: 'dobGrid',
  headerName: 'DOB',
  comparator: _datagrid.ACDateComparatorSorting,
  filter: 'agDateColumnFilter',
  filterParams: {
    buttons: ['apply', 'reset'],
    closeOnApply: true,
    suppressAndOrCondition: true,
    comparator: _datagrid.ACDateComparatorFiltering,
    browserDatePicker: true
  }
}, // {
//   field: 'isActiveStatus',
//   headerName: 'Status',
//   cellRenderer: 'statusRenderer'
// },
// {
//   field: 'userId',
//   headerName: 'Agent ID'
// },
{
  field: 'managerId',
  headerName: 'Manager ID'
} // {
//   field: 'userRoles',
//   headerName: 'Role'
// },
// {
//   field: 'userSkill',
//   headerName: 'Skills'
// }
];
exports.ColumnDefs = ColumnDefs;