"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchUsers = exports.updateSkillsForUser = exports.updateRolesForUser = exports.createOrUpdateUser = exports.fetchAgents = void 0;

var _basic = require("../../basic");

var _lodash = _interopRequireDefault(require("lodash"));

var _moment = _interopRequireDefault(require("moment"));

var _uuid = require("uuid");

var _utils = require("../../utils");

var _AgentActions = require("./AgentActions");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var PAGE_SIZE = 30;
var campaign = ['pmedjun01', 'pmedjmay15', 'pmedjun01', 'pmedjun15'];
var fn = ['Danielle', 'Andy', 'Parker', 'Mark', 'Jack'];
var ln = ['McNally', 'Mendoza', 'Priola', 'Perry', 'Evans'];
var status = ['active', 'inactive'];
var disposition = ['sale', 'callback'];

function randomDate(start, end) {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
}

var fetchAgents = function fetchAgents() {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [];

      _lodash["default"].times(PAGE_SIZE, function (i) {
        var firstName = fn[(0, _basic.getRandomInt)(0, fn.length - 1)];
        var lastName = ln[(0, _basic.getRandomInt)(0, ln.length - 1)];
        var email = firstName.toLowerCase() + '.' + lastName.toLowerCase() + '@gmail.com';
        data.push({
          id: (0, _uuid.v4)(),
          userId: 'Agent-' + (0, _basic.getRandomInt)(100, 999),
          source: 'Third party',
          campaign: campaign[(0, _basic.getRandomInt)(0, campaign.length - 1)],
          firstName: firstName,
          lastName: lastName,
          dob: (0, _moment["default"])(randomDate(new Date(1970, 0, 1), new Date(2000, 0, 1))).format(_utils.AC_CONSTANTS.AC_DATE_FORMAT),
          doj: (0, _moment["default"])(randomDate(new Date(2020, 0, 1), new Date())).format(_utils.AC_CONSTANTS.AC_DATE_FORMAT),
          phone1: "(".concat((0, _basic.getRandomInt)(100, 999), ") ").concat((0, _basic.getRandomInt)(100, 999), "-").concat((0, _basic.getRandomInt)(1000, 9999)),
          emailId: email,
          agentStatus: status[(0, _basic.getRandomInt)(0, status.length - 1)],
          lastcontact: (0, _moment["default"])(randomDate(new Date(2019, 0, 1), new Date())).format(_utils.AC_CONSTANTS.AC_DATE_FORMAT),
          disposition: disposition[(0, _basic.getRandomInt)(0, disposition.length - 1)]
        });
      });

      resolve({
        ok: true,
        data: data
      });
    }, 1000);
  });
};

exports.fetchAgents = fetchAgents;

var createOrUpdateUser = function createOrUpdateUser(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [{
        managerId: null,
        firstName: 'Yogi',
        lastName: 'Chettiar',
        emailId: 'shrikant@xyz.com',
        dob: null,
        gender: null,
        userId: 1,
        addressLn1: null,
        addressLn2: null,
        city: null,
        state: null,
        zip: null,
        phone1: null,
        phone2: null,
        commissionScale: null,
        login: null,
        password: null,
        languagesPreferred: null,
        createdDateTime: 1594923513000,
        modifiedDateTime: 1594923513000,
        createdBy: '2020-07-16 18:18:33',
        modifiedBy: '',
        version: 1,
        agentRoleList: null,
        agentEntitlementList: null
      }];
      resolve({
        ok: true,
        data: data
      });
    }, 500);
  });
};

exports.createOrUpdateUser = createOrUpdateUser;

var updateRolesForUser = function updateRolesForUser(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [];
      resolve({
        ok: true,
        data: data
      });
    }, 500);
  });
};

exports.updateRolesForUser = updateRolesForUser;

var updateSkillsForUser = function updateSkillsForUser(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [];
      resolve({
        ok: true,
        data: data
      });
    }, 500);
  });
};

exports.updateSkillsForUser = updateSkillsForUser;

var fetchUsers = function fetchUsers(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [{
        managerId: null,
        firstName: 'Shri',
        lastName: 'Admin',
        emailId: 'shrikant@xyz.com',
        dob: '2020-06-30 00:00:00',
        gender: null,
        agentStatus: null,
        userId: 1,
        addressLn1: null,
        addressLn2: null,
        city: null,
        state: null,
        zip: null,
        phone1: '(786)7043983',
        phone2: '1321321321',
        commissionScale: null,
        login: 'Shri Login ID',
        password: null,
        languagesPreferred: null,
        createdDateTime: '2020-07-16 18:18:33',
        modifiedDateTime: '2020-08-13 21:00:38',
        createdBy: '2020-07-16 18:18:33',
        modifiedBy: '0',
        version: 14,
        isActiveStatus: 'Y'
      }, {
        managerId: 1,
        firstName: 'Yogi',
        lastName: 'Chettiar',
        emailId: 'Yogi@xyz.com',
        dob: '2020-07-27 00:00:00',
        gender: null,
        agentStatus: null,
        userId: 2,
        addressLn1: 'South California',
        addressLn2: null,
        city: null,
        state: null,
        zip: null,
        phone1: '(0217) 007',
        phone2: null,
        commissionScale: null,
        login: 'yogi_123',
        password: null,
        languagesPreferred: null,
        createdDateTime: '2020-07-27 12:12:18',
        modifiedDateTime: '2020-07-27 19:01:50',
        createdBy: '0',
        modifiedBy: '0',
        version: 4,
        isActiveStatus: 'Y'
      }, {
        managerId: 2,
        firstName: 'Agent',
        lastName: 'Agent',
        emailId: 'a.b@c',
        dob: '2020-07-16 00:00:00',
        gender: 'Female',
        agentStatus: null,
        userId: 3,
        addressLn1: 'address',
        addressLn2: null,
        city: null,
        state: null,
        zip: null,
        phone1: '1234',
        phone2: null,
        commissionScale: null,
        login: '123',
        password: null,
        languagesPreferred: '1',
        createdDateTime: '2020-07-27 15:40:16',
        modifiedDateTime: '2020-07-28 20:48:38',
        createdBy: '0',
        modifiedBy: '0',
        version: 10,
        isActiveStatus: 'Y'
      }, {
        managerId: 1,
        firstName: 'Analyst',
        lastName: 'Analyst',
        emailId: '',
        dob: '2020-07-07 00:00:00',
        gender: 'Female',
        agentStatus: null,
        userId: 4,
        addressLn1: null,
        addressLn2: null,
        city: null,
        state: null,
        zip: null,
        phone1: '12345',
        phone2: null,
        commissionScale: null,
        login: 'AnalystLogin',
        password: null,
        languagesPreferred: null,
        createdDateTime: '2020-07-27 15:41:33',
        modifiedDateTime: '2020-07-27 21:54:04',
        createdBy: '0',
        modifiedBy: '0',
        version: 7,
        isActiveStatus: 'Y'
      }];
      (0, _AgentActions.setUsersDropdown)({
        usersLoading: false,
        error: false,
        errorMsg: '',
        data: [].concat(data)
      });
      resolve({
        ok: true,
        data: data
      });
    }, 500);
  });
};

exports.fetchUsers = fetchUsers;