"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ColumnDefs = void 0;

var _datagrid = require("../datagrid");

var ColumnDefs = [{
  field: 'visibilityRole',
  headerName: 'Visibility Role'
}, {
  field: 'menu',
  headerName: 'Menu'
} // {
//   field: 'createdDateTime',
//   headerName: 'Created Date'
// },
// {
//   field: 'createdBy',
//   headerName: 'Created By'
// }
];
exports.ColumnDefs = ColumnDefs;