"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "NewLeadPage", {
  enumerable: true,
  get: function get() {
    return _NewLeadPage.NewLeadPage;
  }
});

var _NewLeadPage = require("./NewLeadPage");