"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchCarrierMaster = exports.createOrUpdateCarrier = exports.fetchAllCarriers = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _ = require("../../");

var MockService = _interopRequireWildcard(require("./CarrierMock"));

var _CarrierMasterActions = require("./CarrierMasterActions");

var _UtilService = require("../../utils/service/UtilService");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var fetchAllCarriers = function fetchAllCarriers() {
  if (_.isMock) {
    return (0, MockService.fetchAllCarriersMock)();
  }

  return (0, _axios["default"])({
    method: 'get',
    url: "".concat(_.serviceContext, "/carrier"),
    data: {}
  }).then(function (_ref) {
    var data = _ref.data;
    // return JSON.parse(data);
    return data;
  });
};

exports.fetchAllCarriers = fetchAllCarriers;

var createOrUpdateCarrier = function createOrUpdateCarrier(params) {
  if (_.isMock) {
    return (0, MockService.createOrUpdateCarrierMock)(params);
  }

  return (0, _axios["default"])({
    method: 'post',
    url: "".concat(_.serviceContext, "/carrier"),
    data: params
  }).then(function (_ref2) {
    var data = _ref2.data;
    // return JSON.parse(data);
    return data;
  });
};

exports.createOrUpdateCarrier = createOrUpdateCarrier;

var fetchCarrierMaster = function fetchCarrierMaster() {
  if (_.isMock) {
    return MockService.fetchCarrierMaster();
  }

  return (0, _axios["default"])({
    method: 'get',
    url: "".concat(_.serviceContext, "/carrier"),
    //?filterFor=display
    data: {},
    params: {
      filterFor: 'display'
    }
  }).then(function (_ref3) {
    var data = _ref3.data;
    // return JSON.parse(data);
    return data;
  }).then(function (response) {
    if (response.ok === true) {
      var selectedCarrier = response.data[0];
      (0, _CarrierMasterActions.setCarrierMaster)({
        carrierLoading: false,
        error: false,
        errorMsg: '',
        selectedCarrier: selectedCarrier,
        data: _toConsumableArray(response.data)
      });
      (0, _UtilService.fetchCampaignsMaster)({
        carrierId: selectedCarrier.carrierId
      });
    } else if (response.ok === false) {
      (0, _CarrierMasterActions.setCarrierMaster)({
        carrierLoading: false,
        error: true,
        errorMsg: 'Carrier Service Failed',
        data: []
      });
    }
  })["catch"](function (err) {
    console.error(err);
    (0, _CarrierMasterActions.setCarrierMaster)({
      carrierLoading: false,
      error: true,
      errorMsg: 'Carrier Service Failed',
      data: []
    });
  });
};

exports.fetchCarrierMaster = fetchCarrierMaster;