"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSkillsForUser = exports.updateRolesForUser = exports.createOrUpdateUser = exports.transformUsersResponse = exports.fetchAllUsersForDropdown = exports.refreshUserService = exports.fetchUsers = exports.fetchUsersBasedOnRole = exports.fetchAgents = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _lodash = _interopRequireDefault(require("lodash"));

var _moment = _interopRequireDefault(require("moment"));

var _uuid = require("uuid");

var _2 = require("../../");

var agentMock = _interopRequireWildcard(require("./AgentMock"));

var _utils = require("../../utils");

var _AgentActions = require("./AgentActions");

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var fetchAgents = function fetchAgents() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    managerId: 1
  };

  if (_2.isMock) {
    return agentMock.fetchAgents(params);
  }

  return (0, _axios["default"])({
    method: 'get',
    url: "".concat(_2.serviceContext, "/userView/list/manager/").concat(params.managerId),
    data: params,
    // headers: { ...headers, 'Content-Type': 'application/json' },
    transformResponse: [function (data) {
      // transform the response
      // console.log(data);
      return data;
    }]
  }).then(function (_ref) {
    var data = _ref.data;
    return JSON.parse(data);
  }).then(function (response) {
    if (response.ok === true) {
      var data = transformUsersResponse(response.data);
      response.data = data.filter(function (o) {
        return o.isActiveStatus !== 'N';
      });
    }

    return response;
  });
};

exports.fetchAgents = fetchAgents;

var fetchUsersBasedOnRole = function fetchUsersBasedOnRole(params) {
  if (_2.isMock) {
    return agentMock.fetchAgents(params);
  }

  return (0, _axios["default"])({
    method: 'get',
    url: "".concat(_2.serviceContext, "/userView/list"),
    data: params
  }).then(function (_ref2) {
    var data = _ref2.data;
    // return JSON.parse(data);
    return data;
  }).then(function (response) {
    if (response.ok === true) {
      var data = transformUsersResponse(response.data);
      response.data = data;
    }

    return response;
  });
};

exports.fetchUsersBasedOnRole = fetchUsersBasedOnRole;

var fetchUsers = function fetchUsers(params) {
  if (_2.isMock) {
    return agentMock.fetchAgents(params);
  }

  return (0, _axios["default"])({
    method: 'get',
    url: "".concat(_2.serviceContext, "/userView/list"),
    data: params
  }).then(function (_ref3) {
    var data = _ref3.data;
    // return JSON.parse(data);
    return data;
  }).then(function (response) {
    if (response.ok === true) {
      var data = transformUsersResponse(response.data);
      response.data = data;
    }

    return response;
  });
};

exports.fetchUsers = fetchUsers;

var refreshUserService = function refreshUserService(params) {
  if (_2.isMock) {
    return agentMock.fetchAgents(params);
  }

  return (0, _axios["default"])({
    method: 'get',
    url: "".concat(_2.serviceContext, "/userView/").concat(params.userId),
    data: {}
  }).then(function (_ref4) {
    var data = _ref4.data;
    // return JSON.parse(data);
    return data;
  }).then(function (response) {
    if (response.ok === true) {
      var data = transformUsersResponse(response.data);
      response.data = data;
    }

    return response;
  });
}; //TODO: This is temporary for Agents to select various users


exports.refreshUserService = refreshUserService;

var fetchAllUsersForDropdown = function fetchAllUsersForDropdown() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  if (_2.isMock) {
    return agentMock.fetchUsers(params);
  }

  return (0, _axios["default"])({
    method: 'get',
    url: "".concat(_2.serviceContext, "/userView/list"),
    data: params
  }).then(function (_ref5) {
    var data = _ref5.data;
    // return JSON.parse(data);
    return data;
  }).then(function (response) {
    if (response.ok === true) {
      var data = transformUsersResponse(response.data);
      response.data = data;
      (0, _AgentActions.setUsersDropdown)({
        usersLoading: false,
        error: false,
        errorMsg: '',
        data: _toConsumableArray(data.filter(function (o) {
          return !!o.userRoles;
        }))
      });
    }

    return response;
  });
};

exports.fetchAllUsersForDropdown = fetchAllUsersForDropdown;

var transformUsersResponse = function transformUsersResponse() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

  var transformedData = _lodash["default"].map(data, function (o) {
    var newObj = {
      dobGrid: o.dob ? (0, _moment["default"])(o.dob, _utils.AC_CONSTANTS.AC_DATE_FORMAT_SERVICE).format(_utils.AC_CONSTANTS.AC_DATE_FORMAT) : null
    };
    return _objectSpread(_objectSpread({}, o), newObj);
  });

  return transformedData;
};

exports.transformUsersResponse = transformUsersResponse;

var createOrUpdateUser = function createOrUpdateUser(params) {
  if (_2.isMock) {
    return agentMock.createOrUpdateUser(params);
  }

  return (0, _axios["default"])({
    method: 'post',
    url: "".concat(_2.serviceContext, "/user"),
    data: params
  }).then(function (_ref6) {
    var data = _ref6.data;
    // return JSON.parse(data);
    fetchAllUsersForDropdown(); //TODO: This is temporary for Agents to select various users

    return data;
  });
};

exports.createOrUpdateUser = createOrUpdateUser;

var updateRolesForUser = function updateRolesForUser(params) {
  if (_2.isMock) {
    return agentMock.updateRolesForUser(params);
  }

  return (0, _axios["default"])({
    method: 'post',
    url: "".concat(_2.serviceContext, "/role/user/").concat(params.userId),
    data: params.roles
  }).then(function (_ref7) {
    var data = _ref7.data;
    // return JSON.parse(data);
    fetchAllUsersForDropdown(); //TODO: This is temporary for Agents to select various users

    return data;
  });
};

exports.updateRolesForUser = updateRolesForUser;

var updateSkillsForUser = function updateSkillsForUser(params) {
  if (_2.isMock) {
    return agentMock.updateSkillsForUser(params);
  }

  return (0, _axios["default"])({
    method: 'post',
    url: "".concat(_2.serviceContext, "/user/skill/").concat(params.userId),
    data: params.skills
  }).then(function (_ref8) {
    var data = _ref8.data;
    // return JSON.parse(data);
    return data;
  });
};

exports.updateSkillsForUser = updateSkillsForUser;