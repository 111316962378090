"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchDashboardmessages = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _DashboardmessagesAction = require("./DashboardmessagesAction");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var fetchDashboardmessages = function fetchDashboardmessages(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [{
        messageId: 14,
        createdBy: 'user1',
        isActiveStatus: 'Y',
        message: 'Test Test Test',
        version: 0,
        modifiedBy: 'user1',
        messagePriority: 'M',
        createdDate: '2020-08-08 07:02:59',
        modifiedDate: '2020-08-08 07:02:59',
        messageStartDate: '2020-08-08 06:26:48',
        messageEndDate: '2020-08-11 11:26:48'
      }];
      resolve({
        ok: true,
        data: data
      });
    }, 500);
  });
};

exports.fetchDashboardmessages = fetchDashboardmessages;