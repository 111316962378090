"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "MyRequestsPage", {
  enumerable: true,
  get: function get() {
    return _MyRequestsPage.MyRequestsPage;
  }
});

var _MyRequestsPage = require("./MyRequestsPage");