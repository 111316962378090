"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setStore = exports.getStore = void 0;

var _createStore = require("./createStore");

var _redux = require("redux");

var _reduxDevtoolsExtension = require("redux-devtools-extension");

var _reduxThunk = _interopRequireDefault(require("redux-thunk"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var store = null; // export const getStore = () => (store ? store : (store = createStore()));
// example middle-ware

var logDispatch = function logDispatch(store) {
  return function (next) {
    return function (action) {
      // console.log('dispatching', action);
      return next(action);
    };
  };
};

var getStore = function getStore() {
  return store ? store : store = (0, _createStore.createStore)({}, (0, _reduxDevtoolsExtension.composeWithDevTools)((0, _redux.applyMiddleware)(_reduxThunk["default"], logDispatch)));
};

exports.getStore = getStore;

var setStore = function setStore(initialStore) {
  // console.log('setStore');
  if (store != null) {
    console.warn('Store is already initialized. Call setStore before the first getStore. This call will be ignored.');
    return;
  }

  if (typeof initialStore.injectReducer != 'function') throw new Error('Store must support .injectReducer');
  return store = initialStore;
};

exports.setStore = setStore;