"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ContactsPage", {
  enumerable: true,
  get: function get() {
    return _ContactsPage.ContactsPage;
  }
});

var _ContactsPage = require("./ContactsPage");