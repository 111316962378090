"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Skills = exports.ACRoles = void 0;
var ACRoles = {
  Agent: 'Agent' // Supervisor: 'Supervisor',
  // Admin: 'Admin',
  // Analyst: 'Analyst'

};
exports.ACRoles = ACRoles;
var Skills = {
  Accounts: 'Accounts',
  Activities: 'Activities',
  Admin: 'Admin',
  Contacts: 'Contacts',
  Expenses: 'Expenses'
};
exports.Skills = Skills;