"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ColumnDefs = void 0;

var _datagrid = require("../../../../datagrid");

var ColumnDefs = [// {
//   lockPosition: true,
//   headerCheckboxSelection: true,
//   headerCheckboxSelectionFilteredOnly: true,
//   checkboxSelection: true,
//   minWidth: 55,
//   maxWidth: 55,
//   filter: false
// },
{
  field: 'CATEGORY',
  headerName: 'Category'
}, {
  field: 'TYPE',
  headerName: 'Type'
}, {
  field: 'PRODUCT',
  headerName: 'Product'
}, {
  field: 'APPLICATION_ID',
  headerName: 'App Id'
}, {
  field: 'APPLICATION_DATE_GRID',
  headerName: 'App Date',
  comparator: _datagrid.ACDateComparatorSorting,
  filter: 'agDateColumnFilter',
  filterParams: {
    buttons: ['apply', 'reset'],
    closeOnApply: true,
    suppressAndOrCondition: true,
    comparator: _datagrid.ACDateComparatorFiltering,
    browserDatePicker: true
  }
}, {
  field: 'MARKET_PLACE',
  headerName: 'Market Place'
}, {
  field: 'AUTOPAY',
  headerName: 'Autopay'
}, {
  field: 'POLICY_TYPE',
  headerName: 'Policy Type'
}, {
  field: 'ASSIGNED_GROUP',
  headerName: 'Assigned Group'
}, {
  field: 'CONTRACT_ID',
  headerName: 'Contract Id'
}, {
  field: 'MEMBERSHIP',
  headerName: 'Membership'
}, {
  field: 'EFFECTIVE_DATE_GRID',
  headerName: 'Effective Date',
  comparator: _datagrid.ACDateComparatorSorting,
  filter: 'agDateColumnFilter',
  filterParams: {
    buttons: ['apply', 'reset'],
    closeOnApply: true,
    suppressAndOrCondition: true,
    comparator: _datagrid.ACDateComparatorFiltering,
    browserDatePicker: true
  }
}, {
  field: 'PLAN',
  headerName: 'Plan'
}, {
  field: 'PRIMARY_CARE',
  headerName: 'Primary Care'
}, {
  field: 'SUBSCRIBERS',
  headerName: '# of Subscribers'
}, {
  field: 'AGENT_NAME',
  headerName: 'Agent Name'
}, {
  field: 'AOR',
  headerName: 'AOR'
}, {
  field: 'LEAD_STATUS',
  headerName: 'Action'
}, {
  field: 'LAST_DISPOSITION',
  headerName: 'Reason'
}, {
  field: 'CUSTOMER_NAME',
  headerName: 'Customer Name'
}, {
  field: 'COUNTY',
  headerName: 'County'
}, {
  field: 'ZIP',
  headerName: 'Zip'
}, {
  field: 'DOB_GRID',
  headerName: 'Date of Birth',
  comparator: _datagrid.ACDateComparatorSorting,
  filter: 'agDateColumnFilter',
  filterParams: {
    buttons: ['apply', 'reset'],
    closeOnApply: true,
    suppressAndOrCondition: true,
    comparator: _datagrid.ACDateComparatorFiltering,
    browserDatePicker: true
  }
}, {
  field: 'SOURCE',
  headerName: 'Source'
}, {
  field: 'CAMPAIGN_NAME',
  headerName: 'Campaign'
}, {
  field: 'EMAIL',
  headerName: 'Email'
}, {
  field: 'PHONE_NUMBER',
  headerName: 'Phone'
}, {
  field: 'POLICY_CREATED_DATE_GRID',
  headerName: 'Create Date',
  comparator: _datagrid.ACDateComparatorSorting,
  filter: 'agDateColumnFilter',
  filterParams: {
    buttons: ['apply', 'reset'],
    closeOnApply: true,
    suppressAndOrCondition: true,
    comparator: _datagrid.ACDateComparatorFiltering,
    browserDatePicker: true
  }
}];
exports.ColumnDefs = ColumnDefs;