"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ColumnDefs = void 0;

var _datagrid = require("../datagrid");

var ColumnDefs = [// {
//   field: 'lookupId',
//   headerName: 'Lookup Id'
// },
{
  field: 'lookupType',
  headerName: 'Lookup'
}, {
  field: 'code',
  headerName: 'Code'
}, {
  field: 'name',
  headerName: 'Name'
}, {
  field: 'value',
  headerName: 'Value'
}, {
  field: 'sequence',
  headerName: 'Sequence'
}, {
  field: 'active',
  headerName: 'Status',
  cellRenderer: 'statusRenderer'
}, {
  field: 'parentType',
  headerName: 'Parent Type'
}, {
  field: 'parentValue',
  headerName: 'Parent Value'
}];
exports.ColumnDefs = ColumnDefs;