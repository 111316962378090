"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createVirtualStore = void 0;

var createVirtualStore = function createVirtualStore(store, storeKey) {
  var getState = function getState() {
    return store.getState()[storeKey];
  };

  return {
    getState: getState,
    subscribe: function subscribe(f) {
      var lastState = getState();
      return store.subscribe(function () {
        return lastState !== getState() && f(lastState = getState());
      });
    }
  };
};

exports.createVirtualStore = createVirtualStore;