"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createOrUpdateBroadcast = exports.fetchBroadcast = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _lodash = _interopRequireDefault(require("lodash"));

var _moment = _interopRequireDefault(require("moment"));

var _uuid = require("uuid");

var _2 = require("../../");

var MockService = _interopRequireWildcard(require("./BroadcastMock"));

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var fetchBroadcast = function fetchBroadcast(params) {
  if (_2.isMock) {
    return MockService.fetchBroadcast(params);
  }

  return (0, _axios["default"])({
    method: 'get',
    url: "".concat(_2.serviceContext, "/broadcast?filterFor=admin"),
    data: {}
  }).then(function (_ref) {
    var data = _ref.data;
    // return JSON.parse(data);
    return data;
  });
};

exports.fetchBroadcast = fetchBroadcast;

var createOrUpdateBroadcast = function createOrUpdateBroadcast(params) {
  if (_2.isMock) {
    return MockService.createOrUpdateBroadcast(params);
  }

  return (0, _axios["default"])({
    method: 'post',
    url: "".concat(_2.serviceContext, "/broadcast/").concat(params.messageId ? params.messageId : ''),
    data: params
  }).then(function (_ref2) {
    var data = _ref2.data;
    // return JSON.parse(data);
    return data;
  });
};

exports.createOrUpdateBroadcast = createOrUpdateBroadcast;