"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "MyApprovalsPage", {
  enumerable: true,
  get: function get() {
    return _MyApprovalsPage.MyApprovalsPage;
  }
});

var _MyApprovalsPage = require("./MyApprovalsPage");