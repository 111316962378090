"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ColumnDefs = void 0;

var _datagrid = require("../datagrid");

var ColumnDefs = [{
  field: 'requestType',
  headerName: 'Request Type'
}, {
  field: 'createdByName',
  headerName: 'Created By'
}, {
  field: 'requestName',
  headerName: 'Expense Name'
}, {
  field: 'requestDesc',
  headerName: 'Request Reason '
}, {
  field: 'amount',
  headerName: 'Amount '
}, {
  field: 'department',
  headerName: 'Department'
}, {
  field: 'category',
  headerName: 'Category'
}, {
  field: 'subCategory',
  headerName: 'Sub-Category'
}, {
  field: 'stage',
  headerName: 'Stage'
}, {
  field: 'state',
  headerName: 'State',
  cellRenderer: 'statusRenderer'
} // {
//   field: 'completedByName',
//   headerName: 'Completed By'
// },
// {
//   field: 'completedDate',
//   headerName: 'Completed Date'
// }
// {
//   field: 'checkedByName',
//   headerName: 'Checked By'
// },
// {
//   field: 'checkedDate',
//   headerName: 'Checked Date'
// }
];
exports.ColumnDefs = ColumnDefs;