"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchProductTypeMaster = exports.createOrUpdateProduct = exports.fetchProductGroupsByProductType = exports.fetchProductsByProductGroup = exports.fetchAllProductGroupInit = exports.fetchAllProductGroup = exports.createOrUpdateProductGroup = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _ProductActions = require("./ProductActions");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var productGroup = [];
var products = {};

var createOrUpdateProductGroup = function createOrUpdateProductGroup(params) {
  return new Promise(function (resolve) {
    setTimeout(function () {
      var newProductGroup = [];

      if (!_lodash["default"].isUndefined(params.productGroupId)) {
        var idx = _lodash["default"].findIndex(productGroup, {
          productGroupId: params.productGroupId
        });

        if (idx !== -1) {
          newProductGroup = _objectSpread({}, params);
          productGroup = [].concat(_toConsumableArray(productGroup.slice(0, idx)), [newProductGroup], _toConsumableArray(productGroup.slice(idx + 1)));
        } else {
          newProductGroup = _objectSpread({
            productGroupId: productGroup.length
          }, params);
          productGroup.push(newProductGroup);
        }
      } else {
        newProductGroup = _objectSpread({
          productGroupId: productGroup.length
        }, params);
        productGroup.push(newProductGroup);
      }

      resolve({
        ok: true,
        data: [newProductGroup]
      });
    }, 200);
  });
};

exports.createOrUpdateProductGroup = createOrUpdateProductGroup;

var fetchAllProductGroup = function fetchAllProductGroup() {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve({
        ok: true,
        data: productGroup
      });
    }, 200);
  });
};

exports.fetchAllProductGroup = fetchAllProductGroup;

var fetchAllProductGroupInit = function fetchAllProductGroupInit() {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve({
        ok: true,
        data: productGroup
      });
      (0, _ProductActions.setProductGroup)(productGroup);
    }, 200);
  });
};

exports.fetchAllProductGroupInit = fetchAllProductGroupInit;

var fetchProductsByProductGroup = function fetchProductsByProductGroup(productGroup) {
  return new Promise(function (resolve) {
    setTimeout(function () {
      var prods = [];

      if (_lodash["default"].has(products, "".concat(productGroup.carrierId, ".").concat(productGroup.productGroupId))) {
        prods = products[productGroup.carrierId][productGroup.productGroupId];
      }

      resolve({
        ok: true,
        data: prods
      });
    }, 200);
  });
};

exports.fetchProductsByProductGroup = fetchProductsByProductGroup;

var fetchProductGroupsByProductType = function fetchProductGroupsByProductType() {
  return new Promise(function (resolve) {
    setTimeout(function () {
      resolve({
        ok: true,
        data: productGroup
      });
    }, 200);
  });
};

exports.fetchProductGroupsByProductType = fetchProductGroupsByProductType;

var createOrUpdateProduct = function createOrUpdateProduct(params) {
  return new Promise(function (resolve) {
    setTimeout(function () {
      var newProduct = [];
      var prods = [];

      if (_lodash["default"].has(products, "".concat(params.carrierId, ".").concat(params.productGroupId))) {
        prods = products[params.carrierId][params.productGroupId];
      }

      if (!_lodash["default"].isUndefined(params.productId)) {
        var idx = _lodash["default"].findIndex(prods, {
          productId: params.productId
        });

        if (idx !== -1) {
          newProduct = _objectSpread({}, params);
          prods = [].concat(_toConsumableArray(prods.slice(0, idx)), [newProduct], _toConsumableArray(prods.slice(idx + 1)));
        } else {
          newProduct = _objectSpread({
            productId: prods.length
          }, params);
          prods.push(newProduct);
        }
      } else {
        newProduct = _objectSpread({
          productId: prods.length
        }, params);
        prods.push(newProduct);
      }

      if (products[params.carrierId]) {
        products[params.carrierId][params.productGroupId] = prods;
      } else {
        products[params.carrierId] = {};
        products[params.carrierId][params.productGroupId] = prods;
      }

      resolve({
        ok: true,
        data: [newProduct]
      });
    }, 200);
  });
};

exports.createOrUpdateProduct = createOrUpdateProduct;

var fetchProductTypeMaster = function fetchProductTypeMaster() {
  return new Promise(function (resolve) {
    setTimeout(function () {
      var data = [{
        productType: 'ACC',
        productTypeDesc: 'Accident'
      }, {
        productType: 'CI',
        productTypeDesc: 'Critical Illness'
      }, {
        productType: 'D',
        productTypeDesc: 'Dental'
      }, {
        productType: 'DD',
        productTypeDesc: 'Drug Part D'
      }, {
        productType: 'HI',
        productTypeDesc: 'Hospital Indemnity'
      }, {
        productType: 'I',
        productTypeDesc: 'Insurance'
      }, {
        productType: 'LFE',
        productTypeDesc: 'Life'
      }, {
        productType: 'LTC',
        productTypeDesc: 'Long Term Care'
      }, {
        productType: 'M',
        productTypeDesc: 'Medical'
      }, {
        productType: 'V',
        productTypeDesc: 'Vision'
      }];
      resolve({
        ok: true,
        data: data
      });
      (0, _ProductActions.setProductTypeMaster)({
        productTypeLoading: false,
        error: false,
        errorMsg: '',
        data: [].concat(data)
      });
    }, 200);
  });
};

exports.fetchProductTypeMaster = fetchProductTypeMaster;