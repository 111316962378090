"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createOrUpdateLookUp = exports.fetchLookUp = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _LookUpAction = require("./LookUpAction");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var fetchLookUp = function fetchLookUp(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [{
        saleTypeId: 1,
        saleTypeName: 'New',
        carrierId: 1,
        clientId: 1,
        createdDateTime: '2020-08-08 13:59:21',
        modifiedDateTime: '2020-08-14 05:53:37',
        createdBy: 'userId',
        modifiedBy: '123',
        isActiveStatus: 'Y'
      }];
      resolve({
        ok: true,
        data: data
      });
    }, 500);
  });
};

exports.fetchLookUp = fetchLookUp;

var createOrUpdateLookUp = function createOrUpdateLookUp(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [_objectSpread({
        lookupId: 2,
        lookupType: 'Type',
        code: 'T01',
        name: 'RECRUITMENT',
        value: null,
        sequence: '1',
        active: Y,
        parentType: null,
        parentValue: null,
        isActiveStatus: 'Y',
        createdBy: 26,
        createdDate: '2021-03-16 19:30:57',
        modifiedBy: 1,
        modifiedDate: '2021-03-16 22:58:35',
        version: 0
      }, params)];
      resolve({
        ok: true,
        data: data
      });
    }, 500);
  });
};

exports.createOrUpdateLookUp = createOrUpdateLookUp;