"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createOrUpdateDependent = exports.fetchDependents = void 0;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var fetchDependents = function fetchDependents(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [{
        dependentId: 1,
        customerId: params.customerId,
        dependentFname: 'Mike',
        dependentMname: 'Something',
        dependentLname: 'Yes',
        relationWithCustomer: 'SON',
        genderId: null,
        dob: null,
        maritalId: null,
        ssn: null,
        email1: null,
        email2: null,
        address1: null,
        address2: null,
        city: null,
        stateId: null,
        zip: null,
        country: null,
        phone1: null,
        phone1Ext: null,
        phone1Type: null,
        phone2: null,
        phone2Ext: null,
        phone2Type: null,
        fax: null,
        languagePref: null,
        createdBy: '1',
        createdDateTime: null,
        modifiedBy: null,
        modifiedDateTime: null,
        version: 1,
        isActiveStatus: 'Y'
      }];
      resolve({
        ok: true,
        data: data
      });
    }, 500);
  });
};

exports.fetchDependents = fetchDependents;

var createOrUpdateDependent = function createOrUpdateDependent(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [_objectSpread({
        dependentId: 1,
        customerId: params.customerId,
        dependentFname: 'Mike',
        dependentMname: 'Something',
        dependentLname: 'Yes',
        relationWithCustomer: 'SON',
        genderId: null,
        dob: null,
        maritalId: null,
        ssn: null,
        email1: null,
        email2: null,
        address1: null,
        address2: null,
        city: null,
        stateId: null,
        zip: null,
        country: null,
        phone1: null,
        phone1Ext: null,
        phone1Type: null,
        phone2: null,
        phone2Ext: null,
        phone2Type: null,
        fax: null,
        languagePref: null,
        createdBy: '1',
        createdDateTime: null,
        modifiedBy: null,
        modifiedDateTime: null,
        version: 1,
        isActiveStatus: 'Y'
      }, params)];
      resolve({
        ok: true,
        data: data
      });
    }, 500);
  });
};

exports.createOrUpdateDependent = createOrUpdateDependent;