"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fetchAllRoles = void 0;

var _RoleActions = require("./RoleActions");

// import { getRandomInt } from '../../basic';
// import _ from 'lodash';
// import moment from 'moment';
// import { v4 } from 'uuid';
// import { AC_CONSTANTS } from '../../utils';
var fetchAllRoles = function fetchAllRoles(params) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var data = [{
        roleId: 1,
        roleName: 'Agent',
        roleDesc: 'Agent',
        createdBy: null,
        modifiedBy: null,
        modifiedDateTime: null,
        createdDateTime: '2020-07-26 18:37:38',
        status: 'ACTIVE'
      }, {
        roleId: 2,
        roleName: 'Supervisor',
        roleDesc: 'Supervisor',
        createdBy: null,
        modifiedBy: null,
        modifiedDateTime: null,
        createdDateTime: '2020-07-26 18:37:42',
        status: 'ACTIVE'
      }, {
        roleId: 3,
        roleName: 'Admin',
        roleDesc: 'Admin',
        createdBy: null,
        modifiedBy: null,
        modifiedDateTime: null,
        createdDateTime: '2020-07-26 18:37:44',
        status: 'ACTIVE'
      }, {
        roleId: 4,
        roleName: 'Analyst',
        roleDesc: 'Analyst',
        createdBy: null,
        modifiedBy: null,
        modifiedDateTime: null,
        createdDateTime: '2020-07-26 18:37:45',
        status: 'ACTIVE'
      }];
      (0, _RoleActions.setRoles)({
        data: data
      });
      resolve({
        ok: true,
        data: data
      });
    }, 500);
  });
};

exports.fetchAllRoles = fetchAllRoles;