"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ColumnDefs = void 0;

var _datagrid = require("../datagrid");

var ColumnDefs = [{
  field: 'requestName',
  headerName: 'Expense Name'
}, {
  field: 'amount',
  headerName: 'Amount'
}, {
  field: 'requestDesc',
  headerName: 'Request Reason'
}, {
  field: 'assignedUserName',
  headerName: 'Approver'
}, {
  field: 'department',
  headerName: 'Department'
}, {
  field: 'category',
  headerName: 'Category'
}, {
  field: 'subCategory',
  headerName: 'Sub-Category'
}, {
  field: 'stage',
  headerName: 'Stage'
}, {
  field: 'state',
  headerName: 'State',
  cellRenderer: 'statusRenderer'
}, {
  field: 'createdByName',
  headerName: 'Created By'
}, {
  field: 'createdDate',
  headerName: 'Created Date'
}];
exports.ColumnDefs = ColumnDefs;